import {
    ADD_ALL_LIKED_PRODUCT_LIST,
    ADD_RECENTLY_VISITED_PRODUCT,
    EMPTY_SELECTED_PRODUCT,
    GET_ALL_LIKED_PRODUCTS_SUCCESS,
    GET_ALL_PRODUCTS_SUCCESS,
    GET_FAVOURITE_PRODUCT_SUCCESS,
    GET_PRODUCT_REVIEW_SUCCESS,
    GET_PRODUCT_SUCCESS,
    GET_SEARCHED_PRODUCTS_SUCCESS,
    SET_SELECTED_PRODUCT
} from "../../actionTypes";

const INITIAL_STATE = {
    allProducts: [],
    allLikedProducts: [],
    recentlyVisitedProducts: [],
    product: {},
    searchedProducts: [],
    productReviews: []
};

const Product = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                allProducts: action.payload.data.rows
            };

        case SET_SELECTED_PRODUCT:
            return {
                ...state,
                product: action.payload
            };

        case GET_FAVOURITE_PRODUCT_SUCCESS:
            return {
                ...state,
                allLikedProducts: action.payload.data.rows
            };

        case ADD_RECENTLY_VISITED_PRODUCT:
            return {
                ...state,
                recentlyVisitedProducts: [...action.payload]
            };

        case GET_SEARCHED_PRODUCTS_SUCCESS:
            return {
                ...state,
                searchedProducts: action.payload.data.rows
            };

        case EMPTY_SELECTED_PRODUCT:
            return {
                ...state,
                product: {}
            };

        case GET_PRODUCT_REVIEW_SUCCESS:
            return {
                ...state,
                productReviews: action.payload.data.rows[0]
            };

        default:
            return state;
    }
};

export default Product;
