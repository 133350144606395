import React from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../theme/styles";

interface ProfileAlertProps {
    isOpen: any;
    onClose: any;
    cancelRef: any;
}

const ProfileAlert: React.FC<ProfileAlertProps> = ({ isOpen, onClose, cancelRef }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {t("home.auth.welcome_to_lotus")}
                    </AlertDialogHeader>

                    <AlertDialogBody> {t("home.auth.would_you_like_to_update_your_profile")}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button
                            bgColor={globalStyles.colors.lightGreen}
                            _active={{
                                bg: globalStyles.colors.lightGreen
                            }}
                            _hover={{
                                bg: globalStyles.colors.lightGreen
                            }}
                            color={"white"}
                            mr={3}
                            onClick={() => {
                                onClose();
                                navigate("/account/profile/create");
                            }}
                        >
                            {t("messages.yes")}
                        </Button>
                        <Button ref={cancelRef} onClick={onClose} ml={3}>
                            {t("messages.no")}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default ProfileAlert;
