import React, { useState } from "react";
import {
    Box,
    Flex,
    Text,
    Button,
    Image,
    Textarea,
    useDisclosure,
    SlideFade,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Avatar
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import ProductService from "../../../services/ProductService";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { globalStyles } from "../../../theme/styles";

interface WriteReviewProps {
    handleProductReview: any;
    setIsPurchased: React.Dispatch<React.SetStateAction<boolean>>;
}

const WriteReview: React.FC<WriteReviewProps> = ({ handleProductReview, setIsPurchased }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    const { productId } = useParams();

    const { t } = useTranslation();

    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [rating, setRating] = useState(0);
    const [review, setReview] = useState("");

    const handleReview = () => {
        if (rating > 0) {
            const data = {
                product_id: productId,
                content: review,
                review_point: rating
            };
            dispatch(
                ProductService.createProductReview(
                    { token: Auth.token, data },
                    (responseData: any) => {
                        handleProductReview();

                        toast({
                            title: t("messages.product.review_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });

                        handleClose();
                        setIsPurchased(false);
                    },
                    (errorData: any) => {
                        return toast({
                            title: errorData.response?.data?.message
                                ? errorData.response?.data?.message
                                : errorData?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        } else {
            return toast({
                title: t("messages.product.please_add_review"),
                status: "error",
                variant: "solid",
                duration: 2000,
                position: "top-right",
                isClosable: true
            });
        }
    };

    const handleClose = () => {
        onClose();
        setRating(0);
    };

    return (
        <Box marginY={4}>
            <Box onClick={onOpen} display={isOpen ? "none" : ""}>
                <Button
                    variant="outline"
                    width={"full"}
                    cursor={"pointer"}
                    className={"box-shadow"}
                    border={"2px"}
                    height={14}
                    borderColor={"borderGrayColor"}
                    borderRadius={"xl"}
                >
                    <Image src="/assets/icons/review.svg" />
                    <Text marginLeft={"2"}> {t("product.writeReview")} </Text>
                </Button>
            </Box>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader> {t("product.writeReview")}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <SlideFade in={isOpen}>
                            <div className="rate">
                                <input
                                    type="radio"
                                    id="star5"
                                    name="rate"
                                    defaultValue={5}
                                    onClick={() => {
                                        setRating(5);
                                    }}
                                />
                                <label htmlFor="star5" title="text">
                                    5 stars
                                </label>
                                <input
                                    type="radio"
                                    id="star4"
                                    name="rate"
                                    defaultValue={4}
                                    onClick={() => {
                                        setRating(4);
                                    }}
                                />
                                <label htmlFor="star4" title="text">
                                    4 stars
                                </label>
                                <input
                                    type="radio"
                                    id="star3"
                                    name="rate"
                                    defaultValue={3}
                                    onClick={() => {
                                        setRating(3);
                                    }}
                                />
                                <label htmlFor="star3" title="text">
                                    3 stars
                                </label>
                                <input
                                    type="radio"
                                    id="star2"
                                    name="rate"
                                    defaultValue={2}
                                    onClick={() => {
                                        setRating(2);
                                    }}
                                />
                                <label htmlFor="star2" title="text">
                                    2 stars
                                </label>
                                <input
                                    type="radio"
                                    id="star1"
                                    name="rate"
                                    defaultValue={1}
                                    onClick={() => {
                                        setRating(1);
                                    }}
                                />
                                <label htmlFor="star1" title="text">
                                    1 star
                                </label>
                            </div>
                        </SlideFade>
                        <Textarea
                            placeholder={t("product.writeReview")}
                            marginY={3}
                            name="review"
                            onChange={(e) => {
                                setReview(e.target.value);
                            }}
                            display={!isOpen ? "none" : ""}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Flex gap={3}>
                            <Button
                                bgColor={globalStyles.colors.lightGreen}
                                _active={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                _hover={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                color={"white"}
                                size="md"
                                border="2px"
                                borderColor="green.500"
                                onClick={handleReview}
                            >
                                {t("account.save")}
                            </Button>

                            <Button
                                backgroundColor={globalStyles.colors.btn.lightGray}
                                color={"white"}
                                size="md"
                                border="2px"
                                borderColor="common.gray"
                                onClick={onClose}
                            >
                                {t("account.cancel")}
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default WriteReview;
