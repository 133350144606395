import {
    ADD_ADDRESS,
    API_INVOCATION,
    DELETE_ADDRESS,
    GETALLADDRESSES,
    GET_ADDRESS,
    UPDATE_ADDRESS
} from "../store/actionTypes";
import { DELETE, GET, PATCH, POST } from "../utils/apiConstant";
import { ADDRESS } from "../utils/url";

class UserProfileService {
    // getAllAddress
    getAllAddress(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GETALLADDRESSES,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ADDRESS,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // addAddress
    addAddress(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: ADD_ADDRESS,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ADDRESS,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    getAddress(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ADDRESS,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ADDRESS + "/?address_id=" + _payload.id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    updateAddress(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: UPDATE_ADDRESS,
            method: PATCH,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ADDRESS + "/" + _payload.id,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    deleteAddress(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_ADDRESS,
            method: DELETE,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ADDRESS + "/" + _payload.id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new UserProfileService();
