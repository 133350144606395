export default {
    LIFF_ID: process.env.REACT_APP_LIFFID,

    REACT_APP_BACKEND: process.env.REACT_APP_BACKEND,

    CAPCHA_CLIENT_SECRET: process.env.REACT_APP_CAPCHA_CLIENT_SECRET,

    COD_CHARGES: process.env.REACT_APP_COD_CHARGES,

    PAYMENT_TYPE: ["COD", "LINE"],

    GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID
};
