import { Box, Flex, Text } from "@chakra-ui/react";
import Heading from "../../../components/general/Heading";

const Returns = () => {
    return (
        <Box>
            <Heading title={"返品について"} />

            <Flex flexDir={"column"} gap={5}>
                {/* defective product */}
                <Box mt={5}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        不良品
                    </Text>

                    <Flex flexDir={"column"} ml={2}>
                        <Text>
                            万一不良品・破損がございましたら、商品到着後２日以内にご連絡下さい。当社過失の場合は交換致しますので、送料着払いで返送をお願い致します。
                        </Text>
                        <Text>
                            （返送される場合は事前にご連絡下さい。ご連絡が無く返送いただきましても、返品に応じかねます）
                        </Text>
                        <Text>商品到着後、代品を送らせていただきます。</Text>
                        <Text>但し、不良品の交換受付は7日以内とさせていただきます。</Text>
                    </Flex>
                </Box>

                {/* Deadline for returns */}
                <Box mt={5}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        お米は生鮮食品です。
                    </Text>

                    <Flex flexDir={"column"} ml={2} gap={3}>
                        <Text>
                            万一不良品・破損がございましたら、商品到着後２日以内にご連絡下さい。当社過失の場合は交換致しますので、送料着払いで返送をお願い致します。
                        </Text>
                        <Text>
                            正当な理由の無い「受け取り拒否」「商品発送後のキャンセル」「長期不在」
                            等のお客様のご都合による配達遅延により返送されて来た場合には、
                        </Text>
                        <Text>
                            商品代金及び往復送料および債権回収に関わる諸費用を加算し御請求させて
                            頂く場合が御座いますのでご了承下さい。
                        </Text>
                    </Flex>
                </Box>

                {/* return shipping */}
                <Box mt={5}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        返品期限
                    </Text>

                    <Flex flexDir={"column"} ml={2}>
                        <Text>初期不良の返品・交換は商品到着後２日以内にご連絡下さい。</Text>
                        <Text> 商品の返送はご連絡後5日以内に弊社宛て着払いで返送をお願いします。</Text>

                        <Text>商品到着後１～３営業日に代品を発送致します。</Text>
                    </Flex>
                </Box>

                <Box mt={5}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        返品送料
                    </Text>

                    <Flex flexDir={"column"} ml={2}>
                        <Text>初期不良の場合は弊社が負担致します。</Text>
                        <Text> お客様都合による返品につきましては、お客様で負担お願い致します。</Text>
                        <Text>お客様の注文ミス等の交換は往復送料のご負担をお願い致します。</Text>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default Returns;
