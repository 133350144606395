import React, { useEffect } from "react";
import { Box, Container, Flex, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import FooterNav from "../../components/footer/FooterNav";

import { globalStyles } from "../../theme/styles";
import DeliveryMethod from "./components/DeliveryMethod";
import PaymentMethod from "./components/PaymentMethod";
import Returns from "./components/Returns";
import Footer from "../../components/footer/Footer";

const Faqs = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box pb={14} position={"relative"} pt={5}>
            <Container maxW={"7xl"} mb={3}>
                <Flex borderBottom={"1px solid"} borderColor={globalStyles.colors.border.lightGray} pb={3}>
                    <Text fontSize={"2xl"} fontWeight={"semibold"}>
                        {t("pages.faq")}
                    </Text>
                </Flex>

                <Flex gap={5} flexDir={"column"}>
                    <DeliveryMethod />
                    <PaymentMethod />
                    <Returns />
                </Flex>
            </Container>

            <Footer />
            <FooterNav />
        </Box>
    );
};

export default Faqs;
