import {
    ADD_ALL_LIKED_PRODUCT_LIST,
    ADD_RECENTLY_VISITED_PRODUCT,
    EMPTY_SELECTED_PRODUCT,
    SET_SELECTED_PRODUCT
} from "../../actionTypes";

// setUserProfile
export const addRecentlyVisitedProduct = (_payload: any) => {
    return { type: ADD_RECENTLY_VISITED_PRODUCT, payload: _payload };
};

export const addLikedProducts = (_payload: any) => {
    return { type: ADD_ALL_LIKED_PRODUCT_LIST, payload: _payload };
};

export const emptySelectedProduct = (_payload: any) => {
    return { type: EMPTY_SELECTED_PRODUCT, payload: _payload };
};

export const setSelectedProduct = (_payload: any) => {
    return { type: SET_SELECTED_PRODUCT, payload: _payload };
};
