import { useEffect, useState } from "react";
import {
    Box,
    Container,
    Text,
    Image,
    Flex,
    Button,
    Heading,
    Card,
    CardBody,
    useToast,
    Spinner,
    Skeleton,
    Badge
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

// icons
import { FaHeart } from "react-icons/fa";

import { globalStyles } from "../../theme/styles";
import RatingStar from "../star/RatingStar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import ProductService from "../../services/ProductService";
import CartService from "../../services/CartService";
import { numberWithCommas } from "../../utils/helperFunctions";

interface ProductCard {
    product: any;
    isLike: boolean;
    handleAllLikedProduct: any;
}

const ProductCard: React.FC<ProductCard> = ({ product, isLike, handleAllLikedProduct }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const Cartdata = useSelector((state: any) => state.Cart.cart);

    const [isLoading, setIsLoading] = useState(false);
    const [itemInCart, setItemInCart] = useState(false);
    const [previosQuantity, setPreviosQuantity] = useState(0);

    const navigate = useNavigate();
    const toast = useToast();

    const navigateToProductPage = () => {
        navigate(`/product/${product._id}`);
    };

    const navigatoToProductReview = () => {
        navigate(`/product/${product._id}#product-rating`);
    };

    const [productLiked, setProductLiked] = useState(isLike);

    const handleLike = () => {
        setIsLoading(true);
        if (productLiked) {
            // remove like
            dispatch(
                ProductService.removeLikedProduct(
                    { token: Auth.token, product_id: product._id },
                    (responseData: any) => {
                        setProductLiked(false);
                        setIsLoading(false);
                        handleAllLikedProduct();
                    },
                    (errorData: any) => {}
                )
            );
        } else {
            // like
            dispatch(
                ProductService.addLikedProduct(
                    { token: Auth.token, product_id: product._id },
                    (responseData: any) => {
                        setProductLiked(true);
                        setIsLoading(false);
                    },
                    (errorData: any) => {}
                )
            );
        }
    };

    const { t } = useTranslation();

    const handleCart = () => {
        let items = 1;
        if (itemInCart) {
            if (previosQuantity < 10) {
                items = previosQuantity + 1;
            } else {
                items = previosQuantity;
            }
        }
        const data = {
            product_id: product._id,
            items
        };
        dispatch(
            CartService.addCartDetails(
                { token: Auth.token, data },
                (responseData: any) => {
                    toast({
                        title: t("messages.cart.item_is_added_in_cart_successfully"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });

                    getCartDetails();
                },
                (errorData: any) => {}
            )
        );
    };

    const getCartDetails = () => {
        dispatch(
            CartService.getCartDetails(
                { token: Auth.token },
                (responseData: any) => {},
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Cartdata.length > 0) {
            for (let i = 0; i < Cartdata.length; i++) {
                if (product._id == Cartdata[i]?.product_id?._id) {
                    setPreviosQuantity(Cartdata[i].items);
                    setItemInCart(true);
                }
            }
        }
    }, [Cartdata]);

    return (
        <Card bgColor={"white"} maxW={"xs"} mx={"auto"}>
            <CardBody>
                <Box>
                    <Box onClick={navigateToProductPage}>
                        <Flex w={"full"} cursor={"pointer"} justifyContent={"center"}>
                            {product?.cover_photo ? (
                                <Image
                                    src={product?.cover_photo?.url}
                                    fit={"cover"}
                                    align={"center"}
                                    w={"auto"}
                                    maxW={"300px"}
                                    h={"auto"}
                                    maxH={"250px"}
                                />
                            ) : (
                                <Skeleton h={"250px"} w={"300px"} />
                            )}
                        </Flex>
                    </Box>

                    <Flex flexDir={"column"} gap={3} mt={6}>
                        {product?.flag === "NEW" && (
                            <Badge variant="solid" colorScheme="green" w={"14"}>
                                NEW
                            </Badge>
                        )}
                        <Box as={"header"}>
                            <Heading
                                lineHeight={1.1}
                                fontWeight={600}
                                fontSize={{ base: "xl", md: "2xl", lg: "3xl" }}
                                _hover={{ color: "blackAlpha.500" }}
                                onClick={navigateToProductPage}
                                cursor={"pointer"}
                            >
                                {product?.name}
                            </Heading>
                            <Text color={"gray.900"} fontWeight={300} fontSize={"lg"}>
                                {numberWithCommas(product?.price)}円({t("product.tax_included")})
                            </Text>
                        </Box>

                        <Flex alignItems={"center"} gap={3} onClick={navigatoToProductReview}>
                            <RatingStar rating={Number(product?.rating)} />
                            <Text>({numberWithCommas(Number(product?.count))})</Text>
                        </Flex>

                        <Flex justifyContent={"space-between"} gap={4}>
                            <Button
                                textTransform={"uppercase"}
                                onClick={handleCart}
                                bgColor={globalStyles.colors.lightGreen}
                                _active={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                _hover={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                color={"white"}
                                my={3}
                            >
                                {t("product.add_to_cart")}
                            </Button>

                            <Box flex={1} display={"flex"} justifyContent={"flex-end"} onClick={handleLike}>
                                {isLoading ? (
                                    <Spinner color="red.500" size="sm" />
                                ) : (
                                    <FaHeart
                                        color={`${
                                            productLiked
                                                ? globalStyles.colors.background.pink
                                                : globalStyles.colors.background.lightGray
                                        }`}
                                        size={"1.5em"}
                                    />
                                )}
                            </Box>
                        </Flex>
                    </Flex>
                </Box>
            </CardBody>
        </Card>
    );
};

export default ProductCard;
