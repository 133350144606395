//react imports
import { BrowserRouter, Routes, Route } from "react-router-dom";

//layout
import Layout from "./layout/Layout";

//pages
import Home from "./pages/home/Home";
import Search from "./pages/search/Search";
import Cart from "./pages/cart/Cart";
import Favourite from "./pages/favourite/Favourite";

// Account Section
import Account from "./pages/account/Account";
import AccountLayout from "./layout/AccountLayout";

import OrderInfo from "./pages/account/subpage/OrderInfo";
import UserProfileForm from "./pages/account/subpage/UserProfileForm";
import Inquiry from "./pages/account/subpage/Inquiry";
import MemberInformation from "./pages/account/subpage/MemberInformation";
import AllAddresses from "./pages/account/subpage/AllAddresses";
import History from "./pages/account/subpage/History";
import AddAddress from "./pages/account/subpage/AddAddress";

import Product from "./pages/product/Product";
import Checkout from "./pages/checkout/Checkout";

import About from "./pages/about-us/About";
import Conditions from "./pages/termsAndCondition.tsx/Conditions";
import Faqs from "./pages/faqs/Faqs";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";

import NotFound from "./pages/NotFound";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import PaymentFailure from "./pages/payment/PaymentFailure";
import { useEffect } from "react";

// React Ga
import ReactGA from "react-ga";
import config from "./utils/config";
import CODSuccess from "./pages/payment/CODSuccess";

const App = () => {
    useEffect(() => {
        ReactGA.initialize(config.GA_TRACKING_ID || "");
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {/*  layout */}
                <Route element={<Layout />}>
                    {/* home  */}
                    <Route index element={<Home />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/favourite" element={<Favourite />} />
                    {/* account */}
                    <Route element={<AccountLayout />} path="/account">
                        <Route index element={<Account />} />
                        <Route path="profile" element={<MemberInformation />} />
                        <Route path="profile/:profileId" element={<UserProfileForm />} />

                        <Route path="address" element={<AllAddresses />} />
                        <Route path="address/:addressId" element={<AddAddress />} />

                        <Route path="history" element={<History />} />
                        <Route path="history/:orderNumber" element={<OrderInfo />} />

                        <Route path="inquiry" element={<Inquiry />} />
                    </Route>
                    {/* dynamic */}
                    <Route path="/product/:productId" element={<Product />} />
                    x
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    {/* line payment  */}
                    <Route path="/thankyou" element={<PaymentSuccess />} />
                    <Route path="/thankyou/:orderId" element={<CODSuccess />} />
                    <Route path="/payment-failure" element={<PaymentFailure />} />
                    {/* static pages  */}
                    <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
                    <Route path={"/terms-and-conditions"} element={<Conditions />} />
                    <Route path={"/faq"} element={<Faqs />} />
                    {/* no match :404 */}
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
