//react scripts flag if heap memory is not enough --max_old_space_size=8000
import { Icon } from "@chakra-ui/react";
import { FaClipboardList, FaQuestion } from "react-icons/fa";
import { MdAccessibilityNew, MdPrivacyTip } from "react-icons/md";
import { globalStyles } from "../theme/styles";
import {
    Account_UserIcon,
    Account_AddressIcon,
    Account_HistoryIcon,
    Account_FavouriteIcon,
    HomeIcon,
    CartIcon,
    AccountIcon,
    HeartIcon,
    SearchIcon,
    UserIcon,
    Account_QnaIcon,
    Account_FaqIcon,
    Account_TermsIcon,
    Account_PolicyIcon
} from "./Icons";

interface IFooterItem {
    id: number;
    label: string;
    subLabel?: string;
    children?: Array<IFooterItem>;
    href?: string;
    icon?: any;
}

//FOOTER_ITEMS menu
export const FOOTER_ITEMS: Array<IFooterItem> = [
    {
        id: 1,
        label: "home",
        href: "/",
        icon: (props: any) => {
            return <HomeIcon boxSize={props.boxSize} stroke={props.stroke} />;
        }
    },
    {
        id: 2,
        label: "search",
        href: "/search",
        icon: (props: any) => {
            return <SearchIcon boxSize={props.boxSize} fill={props.fill} />;
        }
    },

    {
        id: 3,
        label: "cart",
        href: "/cart",
        icon: (props: any) => {
            return <CartIcon boxSize={props.boxSize} fill={props.fill} />;
        }
    },

    {
        id: 4,
        label: "like",
        href: "/favourite",
        icon: (props: any) => {
            return <HeartIcon boxSize={props.boxSize} fill={props.fill} />;
        }
    },

    {
        id: 5,
        label: "account",
        href: "/account",
        icon: (props: any) => {
            return <AccountIcon boxSize={props.boxSize} fill={props.fill} />;
        }
    }
];

interface IAccountItem {
    id: number;
    label: string;
    href: string;
    icon: any;
}

// Account
export const ACCOUNT_ITEMS: Array<IAccountItem> = [
    {
        id: 1,
        label: "member_info",
        href: "/account/profile",
        icon: <Account_UserIcon />
    },
    {
        id: 2,
        label: "delivery_address",
        href: "/account/address",
        icon: <Account_AddressIcon />
    },

    {
        id: 3,
        label: "order_history",
        href: "/account/history",
        icon: <Account_HistoryIcon />
    },
    {
        id: 4,
        label: "favourite",
        href: "/favourite",
        icon: <Account_FavouriteIcon />
    },
    {
        id: 5,
        label: "inquiry",
        href: "/account/inquiry",
        icon: <Account_FaqIcon />
    },
    {
        id: 6,
        label: "faq",
        href: "/faq",
        icon: <Account_QnaIcon />
    },
    {
        id: 7,
        label: "pricay_policy",
        href: "/privacy-policy",
        icon: <Account_PolicyIcon />
    },
    {
        id: 8,
        label: "terms_and_conditions",
        href: "/terms-and-conditions",
        icon: <Account_TermsIcon />
    }
];
