import { Box, Button, Container, Divider, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { globalStyles } from "../../theme/styles";

import Img from "../../assets/loading-success.gif";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import PaymentService from "../../services/PaymentService";
import OrderService from "../../services/OrderService";
import LoadingScreen from "../../components/loading/LoadingScreen";
import ProductHistory from "../../components/product/ProductHistory";

import ReactGA from "react-ga";
import { toggleViewCancelOrder } from "../../store/actions/order";

const PaymentSuccess = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const viewOrder = () => {
        dispatch(toggleViewCancelOrder(false));
        navigate(`/account/history/${searchParams.get("orderId")}`);
    };

    const handleConfirmPayment = () => {
        setIsLoading(true);

        const data = {
            transactionId: searchParams.get("transactionId"),
            orderId: searchParams.get("orderId")
        };
        dispatch(
            PaymentService.confirmPayment(
                {
                    token: Auth.token,
                    data
                },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {
                    navigate(`/account/history/${searchParams.get("orderId")}`);
                }
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            handleConfirmPayment();
        }
    }, [Auth.token]);

    useEffect(() => {
        window.scrollTo(0, 0);

        ReactGA.pageview(window.location.pathname + window.location.search);
        setIsLoading(true);
    }, []);

    return (
        <Box bgColor={"White"} minH={"100vh"}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxW={"7xl"} mb={3}>
                    <Image src={Img} w={"200px"} mx={"auto"} />

                    <Flex flexDir={"column"} gap={2} justifyContent={"center"} alignItems={"center"}>
                        <Text fontSize={"2xl"} color={"#1EBE79"} fontWeight={"bold"} textAlign={"center"}>
                            {t("account.history_mgmt.your_payment_is_successful")}
                        </Text>

                        <Text color={"#9E9EA0"}> {t("account.history_mgmt.your_order_processed")}</Text>
                        <Text color={"#9E9EA0"}>
                            {t("account.history_mgmt.details_of_transaction_are_given_below")}
                        </Text>
                    </Flex>

                    <Divider color={"#9E9EA0"} h={"1.5"} />

                    <Text textAlign={"center"} mt={4} mb={8} fontWeight={"bold"}>
                        {t("account.history_mgmt.transaction_number")}: {searchParams.get("transactionId")}
                    </Text>

                    <Flex justifyContent={"center"}>
                        <Button
                            bgColor={globalStyles.colors.lightGreen}
                            _active={{
                                bg: globalStyles.colors.lightGreen
                            }}
                            _hover={{
                                bg: globalStyles.colors.lightGreen
                            }}
                            color={"white"}
                            textTransform={"uppercase"}
                            onClick={viewOrder}
                        >
                            {t("account.history_mgmt.view_order")}
                        </Button>
                    </Flex>
                </Container>
            )}
        </Box>
    );
};

export default PaymentSuccess;
