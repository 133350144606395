import { API_INVOCATION, GET_PROFILE_DETAILS, SET_PROFILE_DETAILS, SET_PROFILE_PICTURE } from "../store/actionTypes";
import { GET, POST } from "../utils/apiConstant";
import { PROFILEDETAILS, USERPROFILE } from "../utils/url";

class UserProfileService {
    // setProfilePicture
    setProfilePicture(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: SET_PROFILE_PICTURE,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: USERPROFILE,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    // getProfileDetails
    getProfileDetails(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_PROFILE_DETAILS,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: USERPROFILE,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // setProfileDetails
    setProfileDetails(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: SET_PROFILE_DETAILS,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: PROFILEDETAILS,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new UserProfileService();
