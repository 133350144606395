export const API_INVOCATION = "API_INVOCATION";

//authentication related actions
export const AUTHENTICATE_API = "AUTHENTICATE_API";
export const AUTHENTICATE_API_SUCCESS = "AUTHENTICATE_API_SUCCESS";
export const AUTHENTICATE_API_ERROR = "AUTHENTICATE_API_ERROR";

// User
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_SELECTED_USER = "SET_SELECTED_USER";

export const GET_PROFILE_DETAILS = "GET_PROFILE_DETAILS";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_ERROR = "GET_PROFILE_DETAILS_ERROR";

export const SET_PROFILE_PICTURE = "SET_PROFILE_PICTURE";
export const SET_PROFILE_PICTURE_SUCCESS = "SET_PROFILE_PICTURE_SUCCESS";
export const SET_PROFILE_PICTURE_ERROR = "SET_PROFILE_PICTURE_ERROR";

export const SET_PROFILE_DETAILS = "SET_PROFILE_DETAILS";
export const SET_PROFILE_DETAILS_SUCCESS = "SET_PROFILE_DETAILS_SUCCESS";
export const SET_PROFILE_DETAILS_ERROR = "SET_PROFILE_DETAILS_ERROR";

// All API used in Home Page
//

// All API used in Account Page

// Account Page
//

// Profile
export const GETUSERPROFILE = "GETUSERPROFILE";
export const ADDUSERPROFILE = "ADDUSERPROFILE";
export const UPDATEUSERPROFILE = "UPDATEUSERPROFILE";

// Add address
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";

export const GETALLADDRESSES = "GETALLADDRESSES";
export const GETALLADDRESSES_SUCCESS = "GETALLADDRESSES_SUCCESS";
export const GETALLADDRESSES_ERROR = "GETALLADDRESSES_ERROR";

export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_ERROR = "ADD_ADDRESS_ERROR";

export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_ERROR = "UPDATE_ADDRESS_ERROR";

export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_ERROR = "GET_ADDRESS_ERROR";

export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_ERROR = "DELETE_ADDRESS_ERROR";

// Inquiry
export const CREATE_INQUIRY = "CREATE_INQUIRY";
export const CREATE_INQUIRY_SUCCESS = "CREATE_INQUIRY_SUCCESS";
export const CREATE_INQUIRY_ERROR = "CREATE_INQUIRY_ERROR";

// Product
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_ERROR = "GET_ALL_PRODUCTS_ERROR";

export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const GET_ALL_LIKED_PRODUCTS = "GET_ALL_LIKED_PRODUCTS";
export const GET_ALL_LIKED_PRODUCTS_SUCCESS = "GET_ALL_LIKED_PRODUCTS_SUCCESS";
export const GET_ALL_LIKED_PRODUCTS_ERROR = "GET_ALL_LIKED_PRODUCTS_ERROR";

export const GET_RECENTLY_VISITED_PRODUCTS = "GET_RECENTLY_VISITED_PRODUCTS";
export const ADD_RECENTLY_VISITED_PRODUCT = "ADD_RECENTLY_VISITED_PRODUCT";

export const GET_SEARCHED_PRODUCTS = "GET_SEARCHED_PRODUCTS";
export const GET_SEARCHED_PRODUCTS_SUCCESS = "GET_SEARCHED_PRODUCTS_SUCCESS";
export const GET_SEARCHED_PRODUCTS_ERROR = "GET_SEARCHED_PRODUCTS_ERROR";

export const ADD_LIKED_PRODUCT = "ADD_LIKED_PRODUCT";
export const ADD_LIKED_PRODUCT_SUCCESS = "ADD_LIKED_PRODUCT_SUCCESS";
export const ADD_LIKED_PRODUCT_ERROR = "ADD_LIKED_PRODUCT_ERROR";

export const REMOVE_LIKED_PRODUCT = "REMOVE_LIKED_PRODUCT";
export const REMOVE_LIKED_PRODUCT_SUCCESS = "REMOVE_LIKED_PRODUCT_SUCCESS";
export const REMOVE_LIKED_PRODUCT_ERROR = "REMOVE_LIKED_PRODUCT_ERROR";

export const ADD_ALL_LIKED_PRODUCT_LIST = "ADD_ALL_LIKED_PRODUCT_LIST";

export const GET_PRODUCT_REVIEW = "GET_PRODUCT_REVIEW";
export const GET_PRODUCT_REVIEW_SUCCESS = "GET_PRODUCT_REVIEW_SUCCESS";
export const GET_PRODUCT_REVIEW_ERROR = "GET_PRODUCT_REVIEW_ERROR";

export const CREATE_PRODUCT_REVIEW = "CREATE_PRODUCT_REVIEW";
export const CREATE_PRODUCT_REVIEW_SUCCESS = "CREATE_PRODUCT_REVIEW_SUCCESS";
export const CREATE_PRODUCT_REVIEW_ERROR = "CREATE_PRODUCT_REVIEW_ERROR";

export const EMPTY_SELECTED_PRODUCT = "EMPTY_SELECTED_PRODUCT";
export const SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT";

export const GET_FAVOURITE_PRODUCT = "GET_FAVOURITE_PRODUCT";
export const GET_FAVOURITE_PRODUCT_SUCCESS = "GET_FAVOURITE_PRODUCT_SUCCESS";
export const GET_FAVOURITE_PRODUCT_ERROR = "GET_FAVOURITE_PRODUCT_ERROR";

// Cart
export const GET_CART = "GET_CART";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_ERROR = "GET_CART_ERROR";

export const ADD_CART = "ADD_CART";
export const DELETE_CART = "DELETE_CART";
export const ADD_CART_INFORMATION = "ADD_CART_INFORMATION";

// Order
export const CREATE_ORDER = "CREATE_ORDER";
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER";
export const TOGGLE_VIEW_CANCEL_ORDER = "TOGGLE_VIEW_CANCEL_ORDER";

export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_ORDERS_SUCCESS = "GET_ALL_ORDERS_SUCCESS";
export const GET_ALL_ORDERS_ERROR = "GET_ALL_ORDERS_ERROR";

export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_ERROR = "CANCEL_ORDER_ERROR";

// Payment
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_ERROR = "CONFIRM_PAYMENT_ERROR";

export const RETRY_PAYMENT = "RETRY_PAYMENT";
export const RETRY_PAYMENT_SUCCESS = "RETRY_PAYMENT_SUCCESS";
export const RETRY_PAYMENT_ERROR = "RETRY_PAYMENT_ERROR";
