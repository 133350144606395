import React from "react";
import { Box, FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import { globalStyles } from "../../theme/styles";
import dayjs from "dayjs";

interface InputFieldProps {
    label: string;
    name: string;
    value: string;
    handleChange: any;
    handleBlur: any;
    errors: any;
    touched: any;
    isMandatory: boolean;
}

const CustomDateField: React.FC<InputFieldProps> = (props) => {
    const today = Date.now();

    const FormatDate = dayjs(today).format("YYYY-MM-DD");
    return (
        <FormControl>
            <FormLabel>
                {props.label}
                {props.isMandatory && (
                    <Text color={"red"} as="span">
                        *
                    </Text>
                )}
            </FormLabel>
            <Input
                name={props.name}
                type={"date"}
                value={props.value}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                isInvalid={props.errors && props.touched}
                errorBorderColor="red.300"
                max={FormatDate}
                border={"2px solid"}
                borderColor={globalStyles.colors.border.gray}
                _focus={{
                    borderColor: globalStyles.colors.border.black
                }}
                _active={{
                    borderColor: globalStyles.colors.border.black
                }}
            />
            {props.errors && props.touched && (
                <Text fontSize={"sm"} mt={1} color={"red.300"}>
                    {props.errors}
                </Text>
            )}
        </FormControl>
    );
};

export default CustomDateField;
