import React, { useState } from "react";
import { Box, Flex, Image, Select, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { globalStyles } from "../../theme/styles";
import { numberWithCommas } from "../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";

interface ProductsGridProps {
    product: any;
}

const ProductHistory: React.FC<ProductsGridProps> = ({ product }) => {
    const navigate = useNavigate();

    const showProduct = () => {
        if (product.product_id.status === "ACTIVE") {
            navigate(`/product/${product?.product_id?._id}`);
        }
    };

    return (
        <Box py={3}>
            <Flex w={"full"} gap={4}>
                <Box cursor={"pointer"}>
                    <Flex
                        justifyContent={"center"}
                        alignItems={"center"}
                        w={"100px"}
                        flexDir={"column"}
                        gap={3}
                        onClick={showProduct}
                    >
                        <Image
                            src={product?.cover_photo?.url}
                            fit={"cover"}
                            align={"center"}
                            w={"auto"}
                            maxW={"full"}
                            h={"auto"}
                            maxH={"100px"}
                        />
                    </Flex>
                </Box>

                <Flex flexDir={"column"} gap={2} w={"full"}>
                    <Text _hover={{ color: globalStyles.colors.lightGreen }} onClick={showProduct}>
                        {product?.name}
                    </Text>
                    {/* <Text> {product?.product_id?.product_number} </Text> */}

                    <Flex alignItems={"center"} justifyContent={"space-between"}>
                        <Text color={globalStyles.colors.text.gray}>
                            {product?.items}個 {product?.product_id && numberWithCommas(Number(product?.price))}円
                        </Text>
                        <Text fontSize={"lg"} fontWeight={"semibold"} mr={3}>
                            {product && numberWithCommas(Number(product?.items) * Number(product?.price))}円
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default ProductHistory;
