import { GETALLADDRESSES_SUCCESS, SET_SELECTED_ADDRESS, SET_USER_PROFILE } from "../../actionTypes";

const INITIAL_STATE = {
    allAddress: [],
    selectedAddress: {}
};

const Address = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GETALLADDRESSES_SUCCESS:
            return {
                ...state,
                allAddress: action.payload.data.rows
            };
        case SET_SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: action.payload
            };
        default:
            return state;
    }
};

export default Address;
