import { Box, Flex, Text, chakra } from "@chakra-ui/react";
import Heading from "../../../components/general/Heading";

const DeliveryMethod = () => {
    return (
        <Box>
            <Heading title={"配送・送料について"} />

            <Flex flexDir={"column"} gap={5}>
                {/* Delivery address information */}
                <Box>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        お届け先情報（佐川急便またはゆうパック）
                    </Text>

                    <Flex flexDir={"column"} ml={2} gap={1}>
                        <Text>全商品表示価格には消費税が含まれております。</Text>
                        <Text>2㎏まで 梱包サイズ80 送料940円（税込）</Text>
                        <Text>4㎏まで 梱包サイズ100 送料1050（税込）</Text>
                        <Text>なお、代引手数料、振込手数料はお客様ご負担とさせていただきます。</Text>
                        <Text>お客様による配送会社の指定はできません。</Text>
                    </Flex>
                </Box>

                {/* Additional charge */}
                {/* <Box>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        ■追加料金■
                    </Text>

                    <Flex flexDir={"column"} ml={2}>
                        <Text>・北海道・九州（離島を除く）一律300円</Text>
                        <Text>・沖縄県（離島を含む）・その他離島地域</Text>
                    </Flex>
                    <Box>2kg：500円、5kg：700円、10kg：1000円、30kg：2000円</Box>
                </Box> */}

                {/* note  */}
                {/* <Flex flexDir={"column"}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        ※離島への配送について
                    </Text>
                    <Flex flexDir={"column"} ml={2}>
                        <Text>離島への配送追加料金は、カート内で加算されません。</Text>
                        <Text>その場合は、「受注確認メール」でお支払総額をお知らせ致します。</Text>
                        <Text>必ず、ご確認をお願い致します。</Text>
                    </Flex>
                </Flex> */}
            </Flex>
        </Box>
    );
};

export default DeliveryMethod;
