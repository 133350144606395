import { API_INVOCATION, CANCEL_ORDER, CREATE_ORDER, GET_ALL_ORDERS, GET_ORDER } from "../store/actionTypes";
import { DELETE, GET, POST } from "../utils/apiConstant";
import { CANCELORDER, ORDER } from "../utils/url";

class OrderService {
    // createOrder
    createOrder(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CREATE_ORDER,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ORDER,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    //Get All Order History
    getAllOrderHistory(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_ORDERS,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ORDER,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // get  Order by ID
    getOrder(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ORDER,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: ORDER + "?id=" + _payload.id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    cancelOrder(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CANCEL_ORDER,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: CANCELORDER,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new OrderService();
