import { GET_PROFILE_DETAILS_SUCCESS, SET_SELECTED_USER } from "../../actionTypes";

const INITIAL_STATE = {
    user: {},
    selectedUser: {}
};

const Profile = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                user: action.payload.data
            };
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.payload
            };
        // case GETUSERPROFILE:
        //     return state;
        // case UPDATEUSERPROFILE:
        //     return { ...action.payload };
        default:
            return state;
    }
};

export default Profile;
