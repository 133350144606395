import { Box, Container, Divider, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { globalStyles } from "../../theme/styles";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import FooterNav from "../../components/footer/FooterNav";
import Footer from "../../components/footer/Footer";

const Conditions = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box pb={14} position={"relative"} pt={5}>
            <Container maxW={"7xl"} mb={3}>
                <Flex borderBottom={"1px solid"} borderColor={globalStyles.colors.border.lightGray} pb={3}>
                    <Text fontSize={"2xl"} fontWeight={"semibold"}>
                        {t("pages.terms_and_conditions")}
                    </Text>
                </Flex>

                <Box>
                    <Flex flexDir={"column"} gap={5}>
                        {/* distributor */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                販売業者
                            </Text>
                            <Text ml={2}>合資会社 羽生惣吾商店</Text>
                        </Box>

                        {/* Operation manager name */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                運営統括責任者名
                            </Text>
                            <Text ml={2}>羽生　惣亮</Text>
                        </Box>

                        {/* post code */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                運営統括責任者名
                            </Text>
                            <Text ml={2}>287-0001</Text>
                        </Box>

                        {/* address */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                住所
                            </Text>
                            <Text ml={2}>千葉県香取市佐原ロ2116-9</Text>
                        </Box>

                        {/* Explanation of charges other than the product price */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                商品代金以外の料金の説明
                            </Text>
                            <Flex ml={2} flexDir={"column"}>
                                <Text>当店の全商品表示価格には消費税が含まれております。</Text>
                                <Text>当店の全商品の販売価格には、配送料が含まれています。</Text>
                                <Text>
                                    ※次の地域は北海道・九州300円/沖縄県500円の送料がかかります。（離島は別途中継料金が加算される場合があります）
                                </Text>
                                <Text>なお、代引手数料、振込手数料はお客様ご負担とさせていただきます。</Text>
                                <Text mt={2}>お客様による配送会社の指定はできません。</Text>
                            </Flex>
                        </Box>

                        {/* Application expiration date */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                申込有効期限
                            </Text>
                            <Text ml={2}>
                                ご注文より7日以内にご入金がない場合は、購入の意思がないものとし注文を自動的にキャンセルとさせていただきます。
                            </Text>
                        </Box>

                        {/* sales volume */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                販売数量
                            </Text>

                            <Flex flexDir={"column"} ml={2} gap={3}>
                                <Text>
                                    数量限定商品につきましては、一度に購入できる数量を限らせていただく場合がございます。
                                </Text>
                            </Flex>
                        </Box>

                        {/* Delivery time */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                引渡し時期
                            </Text>

                            <Flex flexDir={"column"} gap={2} ml={2}>
                                <Flex flexDir={"column"}>
                                    <Text>【1】 銀行振込の場合</Text>
                                    <Text>ご注文後、入金確認し1～３日以内での発送となります。</Text>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text>【2】 代金引換の場合</Text>
                                    <Text>ご注文から１～３日以内発送いたします。</Text>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text>【3】 クレジットカード決済の場合</Text>
                                    <Text>入金確認後1～３日以内での発送となります。</Text>
                                </Flex>
                                <Flex flexDir={"column"} gap={3}>
                                    <Flex flexDir={"column"}>
                                        <Text>【お届け時間指定について】</Text>
                                        <Text>以下の時間帯をご指定することができます。</Text>
                                        <Text>午前中、12-14時、14-16時、16-18時、18-20時</Text>
                                    </Flex>

                                    <Text>
                                        交通事情・天候等により、ご希望いただいた日時にお届けできない場合があります。
                                    </Text>

                                    <Flex flexDir={"column"}>
                                        <Text>
                                            代引きをご指定された場合は、配送業者でのお荷物の保管期限は7日となります。
                                        </Text>
                                        <Text>保管期限を過ぎますとお戻しとなり再度発送が必要となります。</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Box>

                        {/* payment method */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                お支払い方法
                            </Text>

                            <Flex flexDir={"column"} ml={2}>
                                <Text>クレジットカード、商品代引</Text>
                                <Text>お支払い期限</Text>

                                <Text>注文承諾メール到着後、7日間以内にお振込み下さい。</Text>
                                <Text>（後払いの場合は、請求書発行14日以内）</Text>
                            </Flex>
                        </Box>

                        {/* Service name */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                サービス名
                            </Text>

                            <Text ml={2}>羽生惣吾商店</Text>
                        </Box>

                        {/* phone number */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                電話番号
                            </Text>

                            <Text ml={2}>0478-52-2212</Text>
                        </Box>

                        {/* public email address */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                公開メールアドレス
                            </Text>

                            <Text ml={2}>suigounomegumi@hanyusogosyoten.co.jp</Text>
                        </Box>

                        {/* homepage address */}
                        <Box>
                            <Text fontSize={"lg"} fontWeight={"semibold"}>
                                ホームページアドレス
                            </Text>

                            <Text ml={2}>https://hanyusogosyoten.co.jp/</Text>
                        </Box>
                    </Flex>
                </Box>
            </Container>

            <Footer />
            <FooterNav />
        </Box>
    );
};

export default Conditions;
