import { Flex, Icon, Text, chakra, Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { globalStyles } from "../../theme/styles";

interface FooterItemProps {
    footer: any;
}

const FooterItem: React.FC<FooterItemProps> = ({ footer }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const Cartdata = useSelector((state: any) => state.Cart.cart);

    const path = location.pathname;

    let footerColor = "";

    const handleFooterColor = () => {
        if (footer.href === path) {
            return (footerColor = globalStyles.colors.lightGreen);
        }

        if (footer.href.length > 1 && path.includes(footer.href)) {
            return (footerColor = globalStyles.colors.lightGreen);
        }

        return (footerColor = "gray");
    };

    handleFooterColor();
    return (
        <Box
            onClick={() => {
                navigate(footer.href);
            }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            flex={1}
            marginX={t(`pages.${footer.label}`).length > 4 ? 2 : 0}
        >
            <Box pos={"relative"}>
                {footer.icon({
                    stroke: footerColor,
                    boxSize: 5,
                    fill: footerColor
                })}

                {footer.label === "cart" && Cartdata.length > 0 && (
                    <chakra.span
                        pos="absolute"
                        top="-1px"
                        right="-1px"
                        px={2}
                        py={1}
                        fontSize="xs"
                        fontWeight="bold"
                        lineHeight="none"
                        bg={globalStyles.colors.lightGreen}
                        transform="translate(50%,-50%)"
                        color="common.white"
                        rounded="full"
                    >
                        {Cartdata.length}
                    </chakra.span>
                )}
            </Box>

            <Text fontSize={{ base: "9", sm: "13" }} color={footerColor}>
                {t(`pages.${footer.label}`)}
            </Text>
        </Box>
    );
};

export default FooterItem;
