import { Box, Container, Flex, Grid, GridItem, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductCardSkeleton from "../../../components/loading/ProductCardSkeleton";
import ProductCard from "../../../components/product/ProductCard";
import ProductService from "../../../services/ProductService";
import UserProfileService from "../../../services/UserProfileService";
import { AppDispatch } from "../../../store";

const AllProducts = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const LikedProduct = useSelector((state: any) => state.Profile.user?.product_likes);

    const AllProducts = useSelector((state: any) => state.Product.allProducts);

    const [isLoading, setIsLoading] = useState(false);

    const handleAllProducts = () => {
        setIsLoading(true);

        dispatch(
            UserProfileService.getProfileDetails(
                { token: Auth.token },
                (responseData: any) => {},
                (errorData: any) => {}
            )
        );

        dispatch(
            ProductService.getAllProducts(
                { token: Auth.token },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };
    useEffect(() => {
        if (Auth.token !== "") {
            handleAllProducts();
        }
    }, [Auth.token]);

    return (
        <Container maxW={"7xl"}>
            {isLoading ? (
                <ProductCardSkeleton />
            ) : (
                <Grid templateColumns={"repeat(1, 1fr)"} gap={6}>
                    {AllProducts.map((product: any) => {
                        let isLike = false;
                        LikedProduct.map((productId: any) => {
                            if (productId == product._id) {
                                isLike = true;
                            }
                        });

                        return (
                            <GridItem key={product._id}>
                                <ProductCard product={product} isLike={isLike} handleAllLikedProduct={() => {}} />
                            </GridItem>
                        );
                    })}
                </Grid>
            )}
        </Container>
    );
};

export default AllProducts;
