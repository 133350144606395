import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useToast
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import AddressService from "../../services/AddressService";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../theme/styles";

interface DeleteAddressModalProps {
    isOpen: any;
    onClose: any;
    id: string;
    getAllAddresses: any;
}

const DeleteAddressModal: React.FC<DeleteAddressModalProps> = ({ isOpen, onClose, id, getAllAddresses }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    const toast = useToast();
    const { t } = useTranslation();

    const handleDeleteAddress = () => {
        dispatch(
            AddressService.deleteAddress(
                { token: Auth.token, id },
                (responseData: any) => {
                    toast({
                        title: t("messages.address.address_delete_success"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                    getAllAddresses();
                    onClose();
                },
                (errorData: any) => {
                    return toast({
                        title: errorData.response?.data?.message
                            ? errorData.response?.data?.message
                            : errorData?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("account.address_mgmt.delete_address")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{t("messages.address.address_delete_warning")}</ModalBody>

                <ModalFooter>
                    <Button backgroundColor={"gray"} color={"white"} mr={3} onClick={onClose}>
                        {t("messages.no")}
                    </Button>
                    <Button backgroundColor="red" color={"white"} onClick={handleDeleteAddress}>
                        {t("messages.yes")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default DeleteAddressModal;
