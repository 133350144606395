import { Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

import FooterNav from "../components/footer/FooterNav";

const AccountLayout = () => {
    return (
        <Flex flexDir={"column"} gap={3} paddingBottom={"16"} position={"relative"}>
            <Outlet />
            <FooterNav />
        </Flex>
    );
};

export default AccountLayout;
