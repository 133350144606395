import {
    ADD_CART,
    API_INVOCATION,
    DELETE_CART,
    GET_CART,
    GET_PROFILE_DETAILS,
    SET_PROFILE_DETAILS,
    SET_PROFILE_PICTURE
} from "../store/actionTypes";
import { DELETE, GET, POST } from "../utils/apiConstant";
import { CART, PROFILEDETAILS, USERPROFILE } from "../utils/url";

class CartService {
    // getCartDetails
    getCartDetails(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_CART,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: CART,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // addCartDetails
    addCartDetails(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: ADD_CART,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: CART,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    // deleteCartDetails
    deleteCartDetails(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: DELETE_CART,
            method: DELETE,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: CART + "/" + _payload.productId,
            resolve,
            reject,
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new CartService();
