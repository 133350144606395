import {
    ADD_ADDRESS,
    ADD_LIKED_PRODUCT,
    API_INVOCATION,
    CREATE_PRODUCT_REVIEW,
    DELETE_ADDRESS,
    GETALLADDRESSES,
    GET_ADDRESS,
    GET_ALL_LIKED_PRODUCTS,
    GET_ALL_PRODUCTS,
    GET_FAVOURITE_PRODUCT,
    GET_PRODUCT,
    GET_PRODUCT_REVIEW,
    GET_SEARCHED_PRODUCTS,
    REMOVE_LIKED_PRODUCT,
    UPDATE_ADDRESS
} from "../store/actionTypes";
import { GET, POST, PUT } from "../utils/apiConstant";
import {
    FAVOURITEPRODUCT,
    LIKEDPRODUCT,
    PRODUCT,
    PRODUCTREVIEW,
    REMOVELIKEDPRODUCT,
    REVIEW,
    SEARCHPRODUCT
} from "../utils/url";

class ProductService {
    // getAllProducts
    getAllProducts(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_PRODUCTS,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: PRODUCT + "?priority=true",
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getAllLikedProducts
    getAllLikedProducts(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_ALL_LIKED_PRODUCTS,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: LIKEDPRODUCT,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getProduct
    getProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_PRODUCT,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: PRODUCT + "?id=" + _payload.id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getSearchedProducts
    getSearchedProducts(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_SEARCHED_PRODUCTS,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: SEARCHPRODUCT + _payload.search,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    addLikedProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: ADD_LIKED_PRODUCT,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: LIKEDPRODUCT + "/" + _payload.product_id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    removeLikedProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: REMOVE_LIKED_PRODUCT,
            method: PUT,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: REMOVELIKEDPRODUCT + "/" + _payload.product_id,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // getProductReview
    getProductReview(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_PRODUCT_REVIEW,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: PRODUCTREVIEW + _payload.id + "&deleted=false&enabled=true",
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    //getFavouriteProduct
    getFavouriteProduct(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: GET_FAVOURITE_PRODUCT,
            method: GET,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: FAVOURITEPRODUCT,
            resolve,
            reject
        };
        return { type: API_INVOCATION, payload };
    }

    // createProductReview
    createProductReview(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CREATE_PRODUCT_REVIEW,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: REVIEW,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new ProductService();
