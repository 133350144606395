import React, { useEffect, useState } from "react";
import { Box, Button, Container, Flex, Heading, Icon, Text, useToast } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import UserProfileService from "../../../services/UserProfileService";

import { globalStyles } from "../../../theme/styles";

// icons
import { AiFillEdit, AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { MdOutlineDateRange } from "react-icons/md";
import { BsFilePerson, BsPerson } from "react-icons/bs";
import { FiAperture } from "react-icons/fi";
import { VscPerson } from "react-icons/vsc";

import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import ProfileValues from "../../../components/account/profile/ProfileValues";
import { setSelectedUser } from "../../../store/actions/user";
import LoadingScreen from "../../../components/loading/LoadingScreen";
import { createPhoneNumber } from "../../../utils/helperFunctions";
import { GenderIcon } from "../../../utils/Icons";

import ReactGA from "react-ga";

const MemberInformation = () => {
    // redux
    const User = useSelector((state: any) => state.Profile.user);

    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleProfileInfo = () => {
        navigate("/account/profile/edit");
        dispatch(setSelectedUser({ ...User, isEdit: true }));
    };

    const handleProfileDetails = () => {
        setIsLoading(true);
        dispatch(
            UserProfileService.getProfileDetails(
                { token: Auth.token },
                (responseData: any) => {
                    if (!responseData.data.first_name) {
                        navigate("/account/profile/create");
                    }
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            handleProfileDetails();
        }
    }, [Auth.token]);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box>
            {isLoading || Object.keys(User).length == 0 ? (
                <LoadingScreen />
            ) : (
                <Container maxW={"7xl"}>
                    <Heading my={4}>
                        <Text>{t("account.member_info")}</Text>
                    </Heading>

                    <Box
                        p={3}
                        bgColor={"white"}
                        shadow={"lg"}
                        border={"1px"}
                        borderColor={"lightgray"}
                        borderRadius={"lg"}
                        boxShadow={"base"}
                    >
                        <Flex flexDir={"column"} gap={2}>
                            {/* name  */}
                            <Flex alignItems={"center"} p={4} gap={5}>
                                <Icon as={BsPerson} fontSize={20} color={globalStyles.colors.lightGreen} />
                                <Text>
                                    {User?.last_name} {User?.first_name}
                                </Text>
                            </Flex>

                            <hr />

                            {User?.first_name_kana && (
                                <Flex flexDir={"column"} gap={3}>
                                    <Flex alignItems={"center"} p={4} gap={5}>
                                        <Icon as={BsFilePerson} fontSize={20} color={globalStyles.colors.lightGreen} />
                                        <Text>
                                            {User?.last_name_kana} {User?.first_name_kana}
                                        </Text>
                                    </Flex>
                                    <hr />
                                </Flex>
                            )}

                            {/* email  */}
                            {User?.email && (
                                <Flex flexDir={"column"} gap={3}>
                                    <Flex alignItems={"center"} p={4} gap={5}>
                                        <Icon as={AiOutlineMail} fontSize={20} color={globalStyles.colors.lightGreen} />
                                        <Text>{User?.email}</Text>
                                    </Flex>
                                    <hr />
                                </Flex>
                            )}

                            {/* mobile  */}
                            {User?.phone_number && (
                                <Flex flexDir={"column"} gap={3}>
                                    <Flex alignItems={"center"} p={4} gap={5}>
                                        <Icon
                                            as={AiOutlinePhone}
                                            fontSize={20}
                                            color={globalStyles.colors.lightGreen}
                                        />
                                        <Text>{createPhoneNumber(User?.phone_number)}</Text>
                                    </Flex>
                                    <hr />
                                </Flex>
                            )}

                            {/* date of birth  */}
                            {User?.phone_number && (
                                <Flex flexDir={"column"} gap={3}>
                                    <Flex alignItems={"center"} p={4} gap={5}>
                                        <Icon
                                            as={MdOutlineDateRange}
                                            fontSize={20}
                                            color={globalStyles.colors.lightGreen}
                                        />
                                        <Text>{dayjs(User?.date_of_birth).format("YYYY/MM/DD")}</Text>
                                    </Flex>
                                    <hr />
                                </Flex>
                            )}

                            {/* gender  */}

                            {User?.gender && (
                                <Flex flexDir={"column"} gap={3}>
                                    <Flex alignItems={"center"} p={4} gap={5}>
                                        <Icon as={VscPerson} fontSize={20} color={globalStyles.colors.lightGreen} />
                                        <Text> {t(`account.profile_mgmt.${User?.gender}`)}</Text>
                                    </Flex>
                                    <hr />
                                </Flex>
                            )}
                        </Flex>

                        <Flex flexDir={"column"} mt={3}>
                            <Button
                                bgColor={globalStyles.colors.lightGreen}
                                _active={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                _hover={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                leftIcon={<Icon as={AiFillEdit} />}
                                onClick={handleProfileInfo}
                                color={"white"}
                            >
                                {t("checkout.edit")}
                            </Button>
                        </Flex>
                    </Box>
                </Container>
            )}
        </Box>
    );
};

export default MemberInformation;
