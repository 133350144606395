import React, { useState } from "react";
import { Box, Flex, Image, Select, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../theme/styles";
import { numberWithCommas } from "../../../utils/helperFunctions";

interface ProductsGridProps {
    product: any;
}

const ProductsGrid: React.FC<ProductsGridProps> = ({ product }) => {
    const { t } = useTranslation();

    return (
        <Box py={3}>
            <Flex w={"full"} gap={4}>
                <Box _hover={{ bgColor: "blackAlpha.100" }} cursor={"pointer"}>
                    <Flex justifyContent={"center"} alignItems={"center"} w={"150px"} flexDir={"column"} gap={3}>
                        <Image
                            src={product?.product_id?.cover_photo?.url}
                            fit={"cover"}
                            align={"center"}
                            w={"auto"}
                            maxW={"full"}
                            h={"auto"}
                            maxH={"100px"}
                        />
                    </Flex>
                </Box>

                <Flex flexDir={"column"} gap={2} w={"full"}>
                    <Text>{product?.product_id?.name}</Text>
                    <Flex alignItems={"center"} justifyContent={"end"}>
                        {/* <Box>
                            <Text> {product?.product_id?.product_number} </Text>
                            <Text> {product?.product_id?.weight} kg</Text>
                        </Box> */}

                        <Box>
                            <Text color={globalStyles.colors.text.gray} textAlign={"end"}>
                                {product?.items}個 {product?.product_id && numberWithCommas(product?.product_id?.price)}
                                円
                            </Text>
                            <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"end"}>
                                {product &&
                                    product?.product_id &&
                                    numberWithCommas(product?.items * product?.product_id?.price)}
                                円
                            </Text>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default ProductsGrid;
