import { API_INVOCATION, CREATE_INQUIRY } from "../store/actionTypes";
import { POST } from "../utils/apiConstant";
import { INQUIRY } from "../utils/url";

class InquiryService {
    createInquiry(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CREATE_INQUIRY,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: INQUIRY,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new InquiryService();
