import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box, Container, Flex, Grid, GridItem, Heading, Text, VStack } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";

import ProductService from "../../services/ProductService";
import { addLikedProducts } from "../../store/actions/product";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

const ProductCard = lazy(() => import("../../components/product/ProductCard"));

import LoadingScreen from "../../components/loading/LoadingScreen";
import Footer from "../../components/footer/Footer";
import FooterNav from "../../components/footer/FooterNav";
import ViewProductsButton from "../../components/general/ViewProductsButton";
import EmptyProducts from "../../components/loading/EmptyProducts";

const Favourite = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const LikedProducts = useSelector((state: any) => state.Product.allLikedProducts);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const handleAllLikedProduct = () => {
        dispatch(
            ProductService.getFavouriteProduct(
                { token: Auth.token },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            handleAllLikedProduct();
        }
    }, [Auth.token]);

    return (
        <Box paddingBottom={12}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Suspense fallback={<LoadingScreen />}>
                    <Container maxW={"7xl"} minH={"md"}>
                        <Heading my={4}>
                            <Text>
                                {t("account.favourite")} ({LikedProducts.length})
                            </Text>
                        </Heading>

                        {LikedProducts.length == 0 ? (
                            <EmptyProducts title={t("favourite.empty_favourite")} />
                        ) : (
                            <Grid templateColumns={"repeat(1, 1fr)"} gap={6} mb={6}>
                                {LikedProducts.map((product: any) => {
                                    return (
                                        <GridItem key={product._id}>
                                            <ProductCard
                                                product={product}
                                                isLike={true}
                                                handleAllLikedProduct={handleAllLikedProduct}
                                            />
                                        </GridItem>
                                    );
                                })}
                            </Grid>
                        )}
                    </Container>
                </Suspense>
            )}

            <Footer />

            <FooterNav />
        </Box>
    );
};

export default Favourite;
