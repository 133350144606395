import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Stack, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import CustomInputField from "../../input/CustomInputField";
import { globalStyles } from "../../../theme/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../store";
import AddressService from "../../../services/AddressService";
import LoadingScreen from "../../loading/LoadingScreen";
import CustomInputNumber from "../../input/CustomInputNumber";

interface AddressFormProps {
    handleClose: any;
}

const AddressForm: React.FC<AddressFormProps> = ({ handleClose }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    const { t } = useTranslation();
    const { addressId } = useParams();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (values: any, actions: any) => {
        if (addressId == "create") {
            dispatch(
                AddressService.addAddress(
                    { token: Auth.token, data: values },
                    (responseData: any) => {
                        toast({
                            title: t("messages.address.address_save_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                        handleClose();

                        //you can manage state here and if any action is required
                    },
                    (errorData: any) => {
                        return toast({
                            title: errorData.response?.data?.message
                                ? errorData.response?.data?.message
                                : errorData?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        } else {
            dispatch(
                AddressService.updateAddress(
                    { token: Auth.token, data: values, id: addressId },
                    (responseData: any) => {
                        toast({
                            title: t("messages.address.address_update_success"),
                            status: "success",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });

                        handleClose();

                        //you can manage state here and if any action is required
                    },
                    (errorData: any) => {
                        return toast({
                            title: errorData.response?.data?.message
                                ? errorData.response?.data?.message
                                : errorData?.message,
                            status: "error",
                            variant: "solid",
                            duration: 2000,
                            position: "top-right",
                            isClosable: true
                        });
                    }
                )
            );
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
        actions.resetForm();
    };

    const addressSchema = yup.object().shape({
        user_name: yup.string().required(t("form_errors.required_fields")),
        street_address: yup.string().required(t("form_errors.required_fields")),
        city: yup.string().required(t("form_errors.required_fields")),
        state: yup.string().required(t("form_errors.required_fields")),
        zipcode: yup.string().min(7, t("form_errors.invalid_pincode")).required(t("form_errors.required_fields"))
    });

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            user_name: "",
            street_address: "",
            city: "",
            state: "",
            zipcode: ""
        },
        validationSchema: addressSchema,
        onSubmit
    });

    const getAddressById = () => {
        setIsLoading(true);
        dispatch(
            AddressService.getAddress(
                { token: Auth.token, id: addressId },
                (responseData: any) => {
                    const address = responseData.data;
                    setFieldValue("user_name", address.user_name);
                    setFieldValue("type", address.type);
                    setFieldValue("street_address", address.street_address);
                    setFieldValue("city", address.city);
                    setFieldValue("state", address.state);
                    setFieldValue("zipcode", address.zipcode);

                    //you can manage state here and if any action is required
                },
                (errorData: any) => {}
            )
        );

        setIsLoading(false);
    };

    useEffect(() => {
        if (Auth.token !== "" && addressId != "create") {
            getAddressById();
        }
    }, [Auth.token]);

    return (
        <Box>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <form onSubmit={handleSubmit} autoComplete="off">
                    <Stack spacing={4} position={"relative"}>
                        <CustomInputField
                            label={t("account.address_mgmt.full_name")}
                            name="user_name"
                            type="text"
                            value={values.user_name}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.user_name}
                            touched={touched.user_name}
                            isMandatory={true}
                        />

                        <CustomInputNumber
                            label={t("account.address_mgmt.zip")}
                            name="zipcode"
                            value={values.zipcode}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.zipcode}
                            touched={touched.zipcode}
                            maxlength={7}
                            isMandatory={true}
                        />

                        <CustomInputField
                            label={t("account.address_mgmt.state")}
                            name="state"
                            type="text"
                            value={values.state}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.state}
                            touched={touched.state}
                            isMandatory={true}
                        />

                        <CustomInputField
                            label={t("account.address_mgmt.city")}
                            name="city"
                            type="text"
                            value={values.city}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.city}
                            touched={touched.city}
                            isMandatory={true}
                        />

                        <CustomInputField
                            label={t("account.address_mgmt.address_line_1")}
                            name="street_address"
                            type="text"
                            value={values.street_address}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.street_address}
                            touched={touched.street_address}
                            isMandatory={true}
                        />
                        <Flex alignItems={"center"} gap={3} paddingBottom={2}>
                            <Button
                                size="md"
                                bgColor={globalStyles.colors.lightGreen}
                                _active={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                _hover={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                color={"white"}
                                disabled={isSubmitting}
                                type={"submit"}
                            >
                                {t("account.save")}
                            </Button>

                            <Button
                                size="md"
                                bgColor={globalStyles.colors.btn.lightGray}
                                color={"white"}
                                _active={{
                                    bg: globalStyles.colors.btn.lightGray
                                }}
                                _hover={{
                                    bg: globalStyles.colors.btn.lightGray
                                }}
                                onClick={handleClose}
                            >
                                {t("account.cancel")}
                            </Button>
                        </Flex>
                    </Stack>
                </form>
            )}
        </Box>
    );
};

export default AddressForm;
