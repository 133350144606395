import React, { useEffect, useState } from "react";
import { Box, Button, Container, Flex, Heading, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import AddressService from "../../../services/AddressService";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

import { FaPlus } from "react-icons/fa";

import { globalStyles } from "../../../theme/styles";

import LoadingScreen from "../../../components/loading/LoadingScreen";
import DeleteAddressModal from "../../../components/modal/DeleteAddressModal";
import { adjustPincode } from "../../../utils/helperFunctions";

const AllAddresses = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const AllAddresses = useSelector((state: any) => state.Address.allAddress);
    const toast = useToast();

    const navigate = useNavigate();
    const [selectedId, setSelectedId] = useState("");

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleAddAddress = () => {
        navigate("/account/address/create");
    };

    const getAllAddresses = () => {
        setIsLoading(true);
        dispatch(
            AddressService.getAllAddress(
                { token: Auth.token },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    const handleEditAddress = (id: any) => {
        navigate(`/account/address/${id}`);
    };

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleDeleteAddress = (id: any) => {
        setSelectedId(id);
        onOpen();
    };

    const showAddressWarning = () => {
        return toast({
            title: t("messages.address.address_delete_failure"),
            status: "error",
            variant: "solid",
            duration: 2000,
            position: "top-right",
            isClosable: true
        });
    };

    useEffect(() => {
        if (Auth.token !== "") {
            getAllAddresses();
        }
    }, [Auth.token]);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Box>
                    <Container maxW={"7xl"}>
                        <Heading my={4}>
                            <Text>{t("account.address_mgmt.my_addresses")}</Text>
                        </Heading>

                        <Button
                            size={"sm"}
                            bgColor={globalStyles.colors.lightGreen}
                            _active={{
                                bg: globalStyles.colors.lightGreen
                            }}
                            _hover={{
                                bg: globalStyles.colors.lightGreen
                            }}
                            color={"white"}
                            leftIcon={<FaPlus />}
                            mb={2}
                            onClick={() => {
                                if (AllAddresses.length < 5) {
                                    handleAddAddress();
                                } else {
                                    showAddressWarning();
                                }
                            }}
                        >
                            {t("account.address_mgmt.add_address")}
                        </Button>
                    </Container>

                    <Container maxW={"7xl"}>
                        <Flex flexDir={"column"} gap={3} mt={4}>
                            {AllAddresses.map((address: any) => {
                                return (
                                    <Box
                                        key={address._id}
                                        bgColor={"white"}
                                        shadow={"lg"}
                                        border={"1px"}
                                        borderColor={"lightgray"}
                                        borderRadius={"lg"}
                                        boxShadow={"base"}
                                        p={5}
                                        gap={2}
                                    >
                                        <Box>
                                            <Text fontWeight={"600"} fontSize={18}>
                                                {address?.user_name}
                                            </Text>
                                            {/* <Text> {address?.type}</Text> */}
                                            <Text>
                                                {address?.zipcode && adjustPincode(address?.zipcode)},{address?.state},{" "}
                                                {address?.city},{address?.street_address}
                                            </Text>
                                            <Box display={"flex"} gap={2} mt={2}>
                                                <Button
                                                    size={"sm"}
                                                    bgColor={globalStyles.colors.lightGreen}
                                                    color={"white"}
                                                    _active={{
                                                        bg: globalStyles.colors.lightGreen
                                                    }}
                                                    _hover={{
                                                        bg: globalStyles.colors.lightGreen
                                                    }}
                                                    onClick={() => {
                                                        handleEditAddress(address._id);
                                                    }}
                                                >
                                                    {t("account.edit")}
                                                </Button>
                                                <Button
                                                    size={"sm"}
                                                    variant={"outline"}
                                                    _hover={{ bgColor: "red.500" }}
                                                    color={"white"}
                                                    bgColor={"red"}
                                                    onClick={() => {
                                                        handleDeleteAddress(address._id);
                                                    }}
                                                >
                                                    {t("account.delete")}
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Flex>
                    </Container>
                </Box>
            )}

            <DeleteAddressModal isOpen={isOpen} onClose={onClose} id={selectedId} getAllAddresses={getAllAddresses} />
        </Box>
    );
};

export default AllAddresses;
