import { combineReducers } from "redux";

// auth
import Auth from "./reducers/auth";

//account
import Profile from "./reducers/user/profile";
import Address from "./reducers/user/address";
import Order from "./reducers/user/order";
import Product from "./reducers/product";

import Cart from "./reducers/cart";

import OrderHistory from "./reducers/order";

const rootReducer = combineReducers({
    Auth,

    // account
    Profile,
    Address,
    Order,
    Product,

    // Cart
    Cart,

    // OrderHistory
    OrderHistory

    //Payment
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
