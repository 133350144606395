import { Box, Container, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaHome } from "react-icons/fa";
import { FOOTER_ITEMS } from "../../utils/constants";
import FooterItem from "./FooterItem";

const FooterNav = () => {
    return (
        <Box pos={"fixed"} bottom={0} h={"14"} w={"full"} background={"common.white"} zIndex={40}>
            <Container maxW="4xl">
                <Flex p={2} justifyContent={"space-between"}>
                    {FOOTER_ITEMS.map((footer: any) => {
                        return <FooterItem footer={footer} key={footer.id} />;
                    })}
                </Flex>
            </Container>
        </Box>
    );
};

export default FooterNav;
