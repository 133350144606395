import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    useToast
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import AddressService from "../../services/AddressService";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../theme/styles";
import OrderService from "../../services/OrderService";
import { useNavigate } from "react-router-dom";
import { toggleViewCancelOrder } from "../../store/actions/order";

interface CancelOrderModalProps {
    isOpen: any;
    onClose: any;
    id: string;
}

const CancelOrderModal: React.FC<CancelOrderModalProps> = ({ isOpen, onClose, id }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const navigate = useNavigate();

    const toast = useToast();
    const { t } = useTranslation();

    const handleCancelOrder = () => {
        const data = {
            order_id: id
        };
        dispatch(
            OrderService.cancelOrder(
                {
                    token: Auth.token,
                    data
                },
                (responseData: any) => {
                    toast({
                        title: t("messages.order.cancel_order_success"),
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });

                    navigate("/account/history");
                    dispatch(toggleViewCancelOrder(false));
                },
                (errorData: any) => {
                    return toast({
                        title: errorData.response?.data?.message
                            ? errorData.response?.data?.message
                            : errorData?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader> {t("account.history_mgmt.cancel_order")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{t("messages.order.cancel_order_warning")}</ModalBody>

                <ModalFooter>
                    <Button backgroundColor={"gray"} color={"white"} mr={3} onClick={onClose}>
                        {t("messages.no")}
                    </Button>
                    <Button backgroundColor="red" color={"white"} onClick={handleCancelOrder}>
                        {t("messages.yes")}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CancelOrderModal;
