import { Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { globalStyles } from "../../theme/styles";

const ViewProductsButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Button
            onClick={() => {
                navigate("/");
            }}
            bgColor={globalStyles.colors.lightGreen}
            _active={{
                bg: globalStyles.colors.lightGreen
            }}
            _hover={{
                bg: globalStyles.colors.lightGreen
            }}
            color={"white"}
            w={"full"}
            my={3}
        >
            {t("cart.view_products")}
        </Button>
    );
};

export default ViewProductsButton;
