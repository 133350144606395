import React, { useEffect, useState } from "react";

import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderService from "../../../services/OrderService";
import LoadingScreen from "../../../components/loading/LoadingScreen";
import OrderCard from "../../../components/account/order/OrderCard";

import ReactGA from "react-ga";

const History = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const OrderData = useSelector((state: any) => state.OrderHistory.allOrders);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const getAllOrdersDetails = () => {
        setIsLoading(true);
        dispatch(
            OrderService.getAllOrderHistory(
                {
                    token: Auth.token
                },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            getAllOrdersDetails();
        }
    }, [Auth.token]);

    useEffect(() => {
        window.scrollTo(0, 0);

        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Container maxW={"7xl"}>
                    <Heading my={4}>
                        <Text>{t("account.order_history")}</Text>
                    </Heading>

                    <Flex width={"full"} flexDir={"column"} gap={4}>
                        {OrderData.map((order: any) => (
                            <OrderCard key={order?._id} order={order} />
                        ))}
                    </Flex>
                </Container>
            )}
        </Box>
    );
};

export default History;
