import { SET_USER_PROFILE } from "../../actionTypes";

const INITIAL_STATE = {
    nextOrders: [],
    previosOrders: [],
    selectedOrder: {}
};

const Order = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case "o":
            return {
                ...state,
                user: action.payload
            };
        default:
            return state;
    }
};

export default Order;
