import { Box, Button, Icon, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdSmsFailed } from "react-icons/md";

import ReactGA from "react-ga";

const PaymentFailure = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <Box py={8}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Icon as={MdSmsFailed} fontSize={96} color={"red"} />
            </Box>
            <Box>
                <Text textAlign={"center"} fontSize={24} color={"#312529"} marginTop={5}>
                    {t("account.history_mgmt.payment_failed")}
                </Text>
                <Text textAlign={"center"} fontSize={18} color={"lightgray"} marginTop={5}>
                    {t("account.history_mgmt.it_seems_we_have_not_received_your_money")}
                </Text>
                <Box display={"flex"} marginTop={5} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        colorScheme="red"
                        size={"md"}
                        borderRadius={"3xl"}
                        px={8}
                        _hover={{ backgroundColor: "none" }}
                        variant="outline"
                    >
                        {t("account.history_mgmt.try_again")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PaymentFailure;
