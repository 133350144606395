import React, { useEffect } from "react";
import { Box, Container, Flex, Image } from "@chakra-ui/react";

import ReactGA from "react-ga";

// components
import Footer from "../../components/footer/Footer";
import FooterNav from "../../components/footer/FooterNav";
import AllProducts from "./components/AllProducts";
import Banner from "./components/Banner";
import ImageCarousel from "./components/ImageCarousel";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Flex flexDir={"column"} gap={3} paddingBottom={12} maxW={"full"} overflow={"hidden"}>
            <Banner />
            <ImageCarousel />

            <Container maxW={"7xl"}>
                <Box mt={5}>
                    <a href="/assets/images/bg1.jpg" target="_blank">
                        <Image src="/assets/images/hp1.jpg" />
                    </a>
                    <a href="/assets/images/bg2.jpg" target="_blank">
                        <Image src="/assets/images/hp2.jpg" />
                    </a>
                </Box>
            </Container>

            <AllProducts />

            <Container maxW={"7xl"}>
                <Box mt={5}>
                    <Image src="/assets/images/info4.jpg" />
                    <Image src="/assets/images/info5.jpg" />
                </Box>
                <Box>
                    {/* <Image src="/assets/images/info2.jpg" /> */}
                    {/* <Image src="/assets/images/info3.jpg" /> */}
                    <Image src="/assets/images/info_footer.jpeg" />
                </Box>
            </Container>

            <Footer />
            <FooterNav />
        </Flex>
    );
};

export default Home;
