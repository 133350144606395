import { Divider, Flex, chakra, Box } from "@chakra-ui/react";
import React from "react";
import { globalStyles } from "../../theme/styles";

interface HeadingProps {
    title: string;
}

const Heading: React.FC<HeadingProps> = ({ title }) => {
    return (
        <chakra.h2 mb={5} m={"50px 10px 20px"} textAlign={"center"} color={"#333"}>
            <Box
                fontSize={"16px"}
                pos={"relative"}
                mb={"0.8em"}
                letterSpacing={"0.1em"}
                _after={{
                    pos: "absolute",
                    top: "50%",
                    display: "block",
                    w: "100%",
                    h: "1px",
                    content: "''",
                    bgColor: "#e85939"
                }}
            >
                <Box
                    as="span"
                    lineHeight={1.3}
                    fontWeight={"bold"}
                    pos={"relative"}
                    zIndex={1}
                    display={"inline-block"}
                    p={"0 1em"}
                    bgColor={"#fff"}
                    color={"#e85939"}
                >
                    {title}
                </Box>
            </Box>
        </chakra.h2>
    );
};

export default Heading;
