export const URL = process.env.REACT_APP_BACKEND;
export const LIFFID = process.env.REACT_APP_LIFFID;

// Authentication
export const USERAUTH = URL + `/user/auth/login`;

// Profile
export const USERPROFILE = URL + "/user/profile";
export const PROFILEDETAILS = URL + "/user/details";
// export const DEACTIVATE_ACCOUNT = URL + "/user/deactivate";

// Address
export const ADDRESS = URL + "/user/address";

// Inquiry
export const INQUIRY = URL + "/inquiry";

// Product
export const PRODUCT = URL + "/product";
export const SEARCHPRODUCT = URL + "/product?q=";
export const LIKEDPRODUCT = URL + "/user/product/like";
export const REMOVELIKEDPRODUCT = URL + "/user/product/unlike";
export const PRODUCTREVIEW = URL + "/review/?product=";

export const FAVOURITEPRODUCT = URL + "/product/watchlist";

// Review
export const REVIEW = URL + "/review";

// Cart
export const CART = URL + "/user/cart";

// Order
export const ORDER = URL + "/order";
export const CANCELORDER = URL + "/order/cancel";

// Payment
export const CONFIRMPAYMENT = URL + "/order/payment/confirm";
export const RETRYPAYMENT = URL + "/order/payment/retrieve";

// Invoice
export const DOWNLOADINVOICE = URL + "/order/invoice";
