import ReactDOM from "react-dom/client";

// redux
import { Provider } from "react-redux";
import store from "./store";

// chkara provider
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";

import App from "./App";
import "./i18n/config";

// theme
import customTheme from "./theme/theme";

import config from "./utils/config";

const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//     <Provider store={store}>
//         <ChakraProvider theme={customTheme}>
//             <App />
//         </ChakraProvider>
//     </Provider>
// );

window.liff
    .init({ liffId: config.LIFF_ID })
    .then(() => {
        root.render(
            <Provider store={store}>
                <ChakraProvider theme={customTheme}>
                    <App />
                </ChakraProvider>
            </Provider>
        );
    })
    .catch((e) => {
        alert(`LIFF error: Please open in Line App`);
    });
