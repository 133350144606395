import React, { useEffect, useState, lazy, Suspense } from "react";
import {
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Heading,
    Icon,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";

import OrderService from "../../../services/OrderService";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import LoadingScreen from "../../../components/loading/LoadingScreen";
import config from "../../../utils/config";
import ProductHistory from "../../../components/product/ProductHistory";

import { adjustPincode, createPhoneNumber, numberWithCommas } from "../../../utils/helperFunctions";
import { globalStyles } from "../../../theme/styles";
import UserProfile from "../../../components/account/profile/UserProfile";
import PaymentService from "../../../services/PaymentService";

const ProductsGrid = lazy(() => import("../../checkout/components/ProductsGrid"));

import ReactGA from "react-ga";
import CancelOrderModal from "../../../components/modal/CancelOrderModal";

import { toggleViewCancelOrder } from "../../../store/actions/order";
import { DOWNLOADINVOICE } from "../../../utils/url";

const OrderInfo = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const OrderData = useSelector((state: any) => state.OrderHistory.selectedOrder);
    const ViewCancel = useSelector((state: any) => state.OrderHistory.viewCancel);

    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const { t } = useTranslation();
    const { orderNumber } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const today = new Date();
    const date = today.getDate();

    const getOrderDetails = () => {
        setIsLoading(true);
        dispatch(
            OrderService.getOrder(
                {
                    token: Auth.token,
                    id: orderNumber
                },
                (responseData: any) => {
                    if (responseData?.data?.status === "PAYMENT-PENDING") {
                        return handleCancelOrder();
                    }
                    const items = responseData.data.items;
                    const array: any = [];
                    items.map((item: any) => {
                        array.push(Number(item.price) * Number(item.items));
                    });

                    const sumWithInitial = array.reduce((a: any, b: any) => Number(a) + Number(b), 0);
                    setSubTotalAmount(sumWithInitial);
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    const handleRetryPayment = () => {
        const data = {
            orderId: orderNumber
        };
        dispatch(
            PaymentService.retrivePayment(
                {
                    token: Auth.token,
                    data
                },
                (responseData: any) => {
                    console.log(responseData);
                    window.location.replace(responseData?.message);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            getOrderDetails();
        }
    }, [Auth.token]);

    useEffect(() => {
        return () => {
            dispatch(toggleViewCancelOrder(true));
        };
    }, []);

    const handleDownloadPDF = () => {
        const url = DOWNLOADINVOICE + "/" + orderNumber;
        window.open(url, "_blank");
    };

    const handleCancelOrder = () => {
        const data = {
            order_id: orderNumber
        };
        dispatch(
            OrderService.cancelOrder(
                {
                    token: Auth.token,
                    data
                },
                (responseData: any) => {
                    getOrderDetails();
                },
                (errorData: any) => {
                    return toast({
                        title: errorData.response?.data?.message
                            ? errorData.response?.data?.message
                            : errorData?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    return (
        <Box mb={5}>
            {isLoading || Object.keys(OrderData).length === 0 ? (
                <LoadingScreen />
            ) : (
                <Suspense fallback={<LoadingScreen />}>
                    <Container maxW={"7xl"}>
                        {/* heading  */}
                        <Flex flexDir={"column"}>
                            <Heading mt={4}>{t("account.history_mgmt.order_information")}</Heading>
                        </Flex>

                        {/* order  */}
                        <Flex flexDir={"column"} gap={3} mt={2}>
                            {/* order basic  */}
                            <Box
                                px={5}
                                py={2}
                                shadow={"lg"}
                                border={"1px"}
                                borderColor={"lightgray"}
                                borderRadius={"lg"}
                                boxShadow={"base"}
                            >
                                <Flex gap={3}>
                                    <Flex flexDir={"column"}>
                                        <Text fontSize={"sm"}>{t("account.history_mgmt.order_date")}:</Text>
                                        <Text fontSize={"sm"}>{t("account.history_mgmt.id")}:</Text>
                                        <Text>
                                            {t("account.history_mgmt.total")}({t("product.tax_included")}):
                                        </Text>
                                    </Flex>
                                    <Flex flexDir={"column"}>
                                        <Text fontSize={"sm"}>
                                            {OrderData?.createdAt &&
                                                dayjs(OrderData?.createdAt).format("YYYY/MM/DD HH:mm")}
                                        </Text>
                                        <Text fontSize={"sm"}>{OrderData?._id}</Text>
                                        <Text fontWeight={600}>
                                            {OrderData?.total_amount &&
                                                numberWithCommas(Number(OrderData?.total_amount))}
                                            円
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex justifyContent={"space-between"} mt={2}>
                                    {OrderData?.status === "PAYMENT-PENDING" ? (
                                        <Button
                                            bgColor={"red"}
                                            _active={{
                                                bg: "red"
                                            }}
                                            _hover={{
                                                bg: "red"
                                            }}
                                            color={"white"}
                                            borderRadius={2}
                                            width={"full"}
                                            onClick={handleRetryPayment}
                                        >
                                            {t("account.history_mgmt.try_again")}
                                        </Button>
                                    ) : OrderData?.status === "DELIVERED" ? (
                                        <Button
                                            bgColor={globalStyles.colors.lightGreen}
                                            _active={{
                                                bg: globalStyles.colors.lightGreen
                                            }}
                                            _hover={{
                                                bg: globalStyles.colors.lightGreen
                                            }}
                                            color={"white"}
                                            borderRadius={2}
                                            width={"full"}
                                            onClick={handleDownloadPDF}
                                        >
                                            {t("account.history_mgmt.download_invoice")}
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </Flex>
                            </Box>

                            {/* user  */}
                            <Flex flexDir={"column"} gap={1}>
                                <Text fontSize={"lg"} my={2} ml={1} fontWeight={"semibold"}>
                                    {t("account.history_mgmt.user_information")}:
                                </Text>
                                <UserProfile
                                    name={`${OrderData?.user?.last_name} ${OrderData?.user?.first_name}`}
                                    phone={OrderData?.user?.phone_number}
                                />
                            </Flex>

                            {/* payment info  */}
                            <Flex flexDir={"column"} gap={1}>
                                <Text fontSize={"lg"} my={2} ml={1} fontWeight={"semibold"}>
                                    {t("account.history_mgmt.payment_information")}:
                                </Text>
                                <Box
                                    px={5}
                                    py={4}
                                    shadow={"lg"}
                                    border={"1px"}
                                    borderColor={"lightgray"}
                                    borderRadius={"lg"}
                                >
                                    <Flex flexDir={"column"}>
                                        <Text fontWeight={"600"} fontSize={18}>
                                            {OrderData?.address?.user_name}
                                        </Text>
                                        {/* <Text>{OrderData?.address?.type}</Text> */}
                                        <Text>
                                            {OrderData?.address?.zipcode && adjustPincode(OrderData?.address?.zipcode)},
                                            {OrderData?.address?.state}, {OrderData?.address?.city},
                                            {OrderData?.address?.street_address}
                                        </Text>
                                    </Flex>
                                    <Divider my={2} />
                                    <Box>
                                        <Flex>
                                            <Text w={"32"}> {t("account.history_mgmt.payment_type")}:</Text>
                                            <Text textAlign={"start"}>
                                                {t(`account.history_mgmt.${OrderData?.payment_type}`)}
                                            </Text>
                                        </Flex>
                                        <Flex my={2}>
                                            <Text w={"32"}>{t("account.history_mgmt.payment_status")}:</Text>
                                            <Badge
                                                variant="solid"
                                                bgColor={
                                                    OrderData?.status === "DELIVERED"
                                                        ? "green"
                                                        : OrderData?.status === "CANCEL"
                                                        ? "red"
                                                        : OrderData?.status === "PAYMENT-PENDING"
                                                        ? "orange"
                                                        : globalStyles.colors.btn.blue
                                                }
                                                w={"fit-content"}
                                            >
                                                {t(`account.history_mgmt.${OrderData?.status}`)}
                                            </Badge>
                                            {/* <Text textAlign={"start"}>
                                                {t(`account.history_mgmt.${OrderData?.status}`)}
                                            </Text>  */}
                                        </Flex>
                                    </Box>
                                </Box>
                            </Flex>

                            {/* items  */}
                            <Flex flexDir={"column"} gap={1}>
                                <Text fontSize={"lg"} my={2} ml={1} fontWeight={"semibold"}>
                                    {t("account.history_mgmt.product_information")}:
                                </Text>

                                <Box shadow={"lg"} border={"1px"} borderColor={"lightgray"} borderRadius={"lg"} py={4}>
                                    <Flex flexDir={"column"}>
                                        {OrderData?.items?.map((product: any) => (
                                            <ProductHistory key={product._id} product={product} />
                                        ))}
                                    </Flex>

                                    <Divider my={3} />

                                    <Flex flexDir={"column"} px={4}>
                                        <Flex justifyContent={"space-between"}>
                                            <Text> {t("account.history_mgmt.total_items")}:</Text>
                                            <Text>{`${OrderData?.total_items}${t("account.history_mgmt.items")}`}</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"}>
                                            <Text>{t("checkout.sub_total")}:</Text>
                                            <Text>{numberWithCommas(subTotalAmount)}円</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"}>
                                            <Text>{t("checkout.delivery_charges")}:</Text>
                                            <Text>
                                                {OrderData?.delivery_charge &&
                                                    numberWithCommas(Number(OrderData?.delivery_charge))}
                                                円
                                            </Text>
                                        </Flex>
                                        {OrderData?.payment_type === "COD" && (
                                            <Flex justifyContent={"space-between"}>
                                                <Text>{t("checkout.cod_charges")}:</Text>
                                                <Text>
                                                    {OrderData?.cod_charge &&
                                                        numberWithCommas(Number(OrderData?.cod_charge))}
                                                    円
                                                </Text>
                                            </Flex>
                                        )}

                                        <Flex justifyContent={"space-between"}>
                                            <Text fontWeight={"600"}>
                                                {t("checkout.total")}({t("product.tax_included")}):
                                            </Text>
                                            <Text fontWeight={"600"}>
                                                {OrderData?.total_amount &&
                                                    numberWithCommas(Number(OrderData?.total_amount))}
                                                円
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Flex>

                            {(OrderData && Number(dayjs(OrderData?.createdAt).format("DD")) != date) ||
                            OrderData?.status === "DELIVERED" ||
                            OrderData?.status === "CANCEL" ||
                            !ViewCancel ? (
                                ""
                            ) : (
                                <Button
                                    bgColor={"red"}
                                    _active={{
                                        bg: "red"
                                    }}
                                    _hover={{
                                        bg: "red"
                                    }}
                                    color={"white"}
                                    borderRadius={2}
                                    width={"full"}
                                    onClick={onOpen}
                                >
                                    {t("account.history_mgmt.cancel_order")}
                                </Button>
                            )}
                        </Flex>
                    </Container>

                    <CancelOrderModal isOpen={isOpen} onClose={onClose} id={orderNumber || ""} />
                </Suspense>
            )}
        </Box>
    );
};

export default OrderInfo;
