import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";
import { globalStyles } from "../../theme/styles";

const ProductCardSkeleton = () => {
    return (
        <Box padding="6" boxShadow="lg" bg="white" rounded={"lg"} mx={4}>
            <Skeleton size="10" />
            <Skeleton height="32" bgColor={globalStyles.colors.lightGreen} />
            <SkeletonText mt="4" noOfLines={6} spacing="4" skeletonHeight="2" />
        </Box>
    );
};

export default ProductCardSkeleton;
