import React from "react";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";

// icons
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";

import { globalStyles } from "../../../theme/styles";
import { createPhoneNumber } from "../../../utils/helperFunctions";

interface UserProfileProps {
    name: string;
    phone: number;
}

const UserProfile: React.FC<UserProfileProps> = ({ name, phone }) => {
    return (
        <Box px={5} py={4} shadow={"lg"} border={"1px"} borderColor={"lightgray"} borderRadius={"lg"}>
            <Box>
                <Flex alignItems={"center"} gap={5}>
                    <Icon as={BsPerson} fontSize={20} color={globalStyles.colors.lightGreen} />
                    <Text>{name}</Text>
                </Flex>

                {/* <Flex alignItems={"center"} gap={5}>
                    <Icon as={AiOutlineMail} fontSize={20} color={globalStyles.colors.lightGreen} />
                    <Text>{email}</Text>
                </Flex> */}

                <Flex alignItems={"center"} gap={5} mt={2}>
                    <Icon as={AiOutlinePhone} fontSize={20} color={globalStyles.colors.lightGreen} />
                    <Text>{phone && createPhoneNumber(phone)}</Text>
                </Flex>
            </Box>
        </Box>
    );
};

export default UserProfile;
