import React, { useEffect } from "react";
import { Box, Container, Flex, Heading, Text, VStack } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

import AddressForm from "../../../components/account/address/AddressForm";

const AddAddress = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClose = () => {
        navigate(-1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box>
            <Container maxW={"7xl"}>
                <Heading my={4}>
                    <Text>{t("account.address_mgmt.add_address")}</Text>
                </Heading>

                <AddressForm handleClose={handleClose} />
            </Container>
        </Box>
    );
};

export default AddAddress;
