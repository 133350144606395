import React, { useEffect, useState, lazy, Suspense } from "react";
import { Box, Flex, Text, Button, Icon, Container, useToast } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import CartService from "../../services/CartService";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../theme/styles";

import { FaShoppingCart } from "react-icons/fa";

import ReactGA from "react-ga";

const ProductsInCart = lazy(() => import("./components/ProductsInCart"));
import FooterNav from "../../components/footer/FooterNav";
import LoadingScreen from "../../components/loading/LoadingScreen";
import { numberWithCommas } from "../../utils/helperFunctions";
import Footer from "../../components/footer/Footer";
import ViewProductsButton from "../../components/general/ViewProductsButton";
import { LoaderIcon } from "../../utils/Icons";
import EmptyProducts from "../../components/loading/EmptyProducts";

const Cart = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const Cartdata = useSelector((state: any) => state.Cart.cart);
    const DeliveryCharge = useSelector((state: any) => state.Cart.deliveryCharge);
    const [isLoading, setIsLoading] = useState(true);
    const [deliveryUpdate, setDeliveryUpdate] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [cartIsExist, setCartIsExist] = useState(true);

    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const getCartDetails = () => {
        setIsLoading(true);
        dispatch(
            CartService.getCartDetails(
                { token: Auth.token },
                (responseData: any) => {
                    if (responseData.status === 202) {
                        setCartIsExist(false);
                    } else {
                        const items = responseData.data.cartDetails.items;
                        const array: any = [];
                        items.map((item: any) => {
                            array.push(Number(item.product_id.price) * Number(item.items));
                        });

                        const sumWithInitial = array.reduce((a: any, b: any) => Number(a) + Number(b), 0);

                        setSubTotalAmount(sumWithInitial);

                        setTotalAmount(Number(responseData.data.totalAmount) - Number(responseData.data.codCharge));
                    }

                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            getCartDetails();
        }
    }, [Auth.token]);

    const d = () => {
        window.open("", "_blank");
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box paddingBottom={12}>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Suspense fallback={<LoadingScreen />}>
                    <Flex flexDir={"column"} gap={3} pb={14} position={"relative"} pt={5}>
                        <Container maxW={"7xl"}>
                            <Flex
                                gap={2}
                                alignItems={"center"}
                                borderBottom={"1px solid"}
                                borderColor={globalStyles.colors.border.lightGray}
                                pb={3}
                            >
                                <Icon as={FaShoppingCart} w={"6"} h={"6"} />
                                <Text fontSize={"2xl"} fontWeight={"semibold"}>
                                    {t("cart.shopping_cart")} ({numberWithCommas(Number(Cartdata.length))})
                                </Text>
                            </Flex>

                            {cartIsExist ? (
                                <Box>
                                    <Box>
                                        {Cartdata.map((cart: any) => {
                                            return (
                                                <ProductsInCart
                                                    product={cart}
                                                    key={cart._id}
                                                    getCartDetails={getCartDetails}
                                                />
                                            );
                                        })}
                                    </Box>

                                    <Flex flexDir={"column"} w={"full"}>
                                        <Flex
                                            justifyContent={"space-between"}
                                            borderBottom={"1px"}
                                            borderColor={globalStyles.colors.border.gray}
                                            py={2}
                                        >
                                            <Text>{t("checkout.sub_total")}</Text>
                                            <Text>{numberWithCommas(subTotalAmount)}円</Text>
                                        </Flex>

                                        <Flex
                                            justifyContent={"space-between"}
                                            borderBottom={"1px"}
                                            borderColor={globalStyles.colors.border.gray}
                                            py={2}
                                        >
                                            <Text>{t("checkout.delivery_charges")}</Text>
                                            {deliveryUpdate ? (
                                                <LoaderIcon width={"20px"} height={"20px"} />
                                            ) : (
                                                <Text>
                                                    {DeliveryCharge && numberWithCommas(Number(DeliveryCharge))}円
                                                </Text>
                                            )}
                                        </Flex>

                                        <Flex
                                            justifyContent={"space-between"}
                                            borderBottom={"1px"}
                                            borderColor={globalStyles.colors.border.gray}
                                            py={2}
                                        >
                                            <Text fontSize={"xl"} fontWeight={"bold"}>
                                                {t("checkout.total")}({t("product.tax_included")})
                                            </Text>
                                            <Text fontSize={"xl"} fontWeight={"bold"}>
                                                {numberWithCommas(Number(totalAmount))}円
                                            </Text>
                                        </Flex>
                                    </Flex> 
                                    <Box>
                                        <Button
                                            onClick={() => {
                                                navigate("/checkout");
                                            }}
                                            bgColor={globalStyles.colors.lightGreen}
                                            _active={{
                                                bg: globalStyles.colors.lightGreen
                                            }}
                                            _hover={{
                                                bg: globalStyles.colors.lightGreen
                                            }}
                                            color={"white"}
                                            w={"full"}
                                            my={3}
                                        >
                                            {t("cart.checkout")}
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <EmptyProducts title={t("cart.empry_cart")} />
                            )}
                        </Container>
                    </Flex>
                </Suspense>
            )}

            <Footer />
            <FooterNav />
        </Box>
    );
};

export default Cart;
