import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

//chakraui
import { Box, useDisclosure } from "@chakra-ui/react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import AuthService from "../services/AuthService";

//custom compopnents
import LoadingScreen from "../components/loading/LoadingScreen";
import PermissionBox from "../components/auth/PermissionBox";
import ProfileAlert from "../components/auth/ProfileAlert";
import ScrollToTop from "../services/ScrollToTop";
import CartService from "../services/CartService";

const Layout = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    const [loading, setLoading] = useState(false);
    const [permissionisAdded, setPermissionisAdded] = useState(true);

    const [isLineToken, setIsLineToken] = useState(false);

    const getCartDetails = (token: string) => {
        dispatch(
            CartService.getCartDetails(
                { token },
                (responseData: any) => {
                    setLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    const authenticatedUser = (lineToken: any) => {
        if (Auth.token === "") {
            dispatch(
                AuthService.login(
                    { accessToken: lineToken },
                    (responseData: any) => {
                        if (responseData.data.isNewUser) {
                            onOpen();
                        }

                        getCartDetails(responseData?.data?.token);

                        //you can manage state here and if any action is required
                    },
                    (errorData: any) => {
                        //you can manage state here and if any action is required

                        setPermissionisAdded(false);
                    }
                )
            );
        }
    };

    const lineLogin = () => {
        const lineDetails = window.liff.getContext();
        const lineBrowser = lineDetails?.type;

        if (lineBrowser === "external") {
            return setPermissionisAdded(false);
        }

        if (!isLineToken) {
            window.liff
                .getProfile()
                .then((profile: any) => {
                    if (profile) {
                        const lineToken = window.liff.getAccessToken();
                        console.log(lineToken);

                        authenticatedUser(lineToken);
                        setIsLineToken(true);
                    }
                })
                .catch((err: any) => {
                    setPermissionisAdded(false);
                });
        }
    };

    ScrollToTop();

    window.liff.ready.then(() => {
        lineLogin();
    });

    window.liff.ready.then(() => {
        lineLogin();
    });

    if (!window.liff.isLoggedIn()) {
        window.liff.login();
        lineLogin();
    }

    return (
        <div>
            {loading === true ? (
                <div className="loading-box">
                    <LoadingScreen />
                </div>
            ) : (
                <Box>
                    {permissionisAdded ? (
                        <Box position={"relative"}>
                            <Box minH={"90vh"}>
                                <ProfileAlert isOpen={isOpen} onClose={onClose} cancelRef={cancelRef} />
                                <Box>
                                    <Outlet />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <PermissionBox />
                    )}
                </Box>
            )}
        </div>
    );
};

export default Layout;
