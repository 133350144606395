import { Box, Container, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { globalStyles } from "../../theme/styles";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import FooterNav from "../../components/footer/FooterNav";
import Footer from "../../components/footer/Footer";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box pb={14} position={"relative"} pt={5}>
            <Container maxW={"7xl"} mb={3}>
                <Flex borderBottom={"1px solid"} borderColor={globalStyles.colors.border.lightGray} pb={3}>
                    <Text fontSize={"2xl"} fontWeight={"semibold"}>
                        {t("pages.pricay_policy")}
                    </Text>
                </Flex>

                <Box display={"flex"} flexDir={"column"} gap={3} mt={3}>
                    {/* 1. Definition of personal information */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            1.個人情報の定義
                        </Text>
                        <Text ml={3}>
                            「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの、及び他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをいいます。
                        </Text>
                    </Flex>

                    {/* 2. Collection of personal information */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            2.個人情報の収集
                        </Text>

                        <Flex flexDir={"column"} ml={3}>
                            <Text>
                                当ショップでは商品のご購入、お問合せをされた際にお客様の個人情報を収集することがございます。
                            </Text>
                            <Text>収集するにあたっては利用目的を明記の上、適法かつ公正な手段によります。</Text>
                            <Box mt={2}>
                                <Text>当ショップで収集する個人情報は以下の通りです。</Text>
                                <Box>
                                    <Text>a) お名前、フリガナ</Text>
                                    <Text>b) ご住所</Text>
                                    <Text>c) お電話番号</Text>
                                    <Text>d) メールアドレス</Text>
                                    <Text>e) パスワード</Text>
                                    <Text>f) 配送先情報</Text>
                                    <Text>g) 当ショップとのお取引履歴及びその内容</Text>
                                    <Text>h) 上記を組み合わせることで特定の個人が識別できる情報</Text>
                                </Box>
                            </Box>
                        </Flex>
                    </Flex>

                    {/* 3. Use of personal information */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            3.個人情報の利用
                        </Text>

                        <Flex flexDir={"column"} ml={3}>
                            <Text>当ショップではお客様からお預かりした個人情報の利用目的は以下の通りです。</Text>

                            <Flex flexDir={"column"}>
                                <Text>a)ご注文の確認、照会</Text>
                                <Text>b)商品発送の確認、照会</Text>
                                <Text>c)お問合せの返信時</Text>
                            </Flex>

                            <Box mt={2}>
                                <Text>
                                    当ショップでは、下記の場合を除いてはお客様の断りなく第三者に個人情報を開示・提供することはいたしません。
                                </Text>
                                <Flex flexDir={"column"}>
                                    <Text>
                                        a)法令に基づく場合、及び国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合
                                    </Text>
                                    <Text>
                                        b)人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合
                                    </Text>
                                    <Text>c)当ショップを運営する会社の関連会社で個人データを共有する場合</Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>

                    {/* 4. Security management of personal information */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            4.個人情報の安全管理
                        </Text>
                        <Text ml={3}>
                            お客様よりお預かりした個人情報の安全管理はサービス提供会社によって合理的、組織的、物理的、人的、技術的施策を講じるとともに、当ショップでは関連法令に準じた適切な取扱いを行うことで個人データへの不正な侵入、個人情報の紛失、改ざん、漏えい等の危険防止に努めます。
                        </Text>
                    </Flex>

                    {/* 5. Correction and deletion of personal information */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            5.個人情報の訂正、削除
                        </Text>
                        <Flex flexDir={"column"} ml={3}>
                            <Box>お客様からお預かりした個人情報の訂正・削除は下記の問合せ先よりお知らせ下さい。</Box>
                            <Box>
                                また、ユーザー登録された場合、当サイトのメニュー「マイアカウント」より個人情報の訂正が出来ます。
                            </Box>
                        </Flex>
                    </Flex>

                    {/* 6.Use of cookies */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            6.cookie(クッキー)の使用について
                        </Text>
                        <Flex flexDir={"column"}>
                            <Text>
                                当社は、お客様によりよいサービスを提供するため、cookie
                                （クッキー）を使用することがありますが、これにより個人を特定できる情報の収集を行えるものではなく、お客様のプライバシーを侵害することはございません。
                            </Text>
                            <Text>
                                ※cookie
                                （クッキー）とは、サーバーコンピュータからお客様のブラウザに送信され、お客様が使用しているコンピュータのハードディスクに蓄積される情報です。
                            </Text>
                        </Flex>
                    </Flex>

                    {/* 7.Use of SSL*/}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            7.SSLの使用について
                        </Text>
                        <Flex flexDir={"column"} ml={2}>
                            <Text>
                                個人情報の入力時には、セキュリティ確保のため、これらの情報が傍受、妨害または改ざんされることを防ぐ目的でSSL（Secure
                                Sockets Layer）技術を使用しております。
                            </Text>
                            <Text>
                                ※
                                SSLは情報を暗号化することで、盗聴防止やデータの改ざん防止送受信する機能のことです。SSLを利用する事でより安全に情報を送信する事が可能となります。
                            </Text>
                        </Flex>
                    </Flex>

                    {/* 8. Contact information */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            8.お問合せ先
                        </Text>
                        <Text ml={2}>定商取引法に基づく表記をご覧ください。</Text>
                    </Flex>

                    {/* 9. Changes to Privacy Policy */}

                    <Flex flexDir={"column"}>
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                            9.プライバシーポリシーの変更
                        </Text>
                        <Flex flexDir={"column"} ml={2}>
                            <Text>
                                当ショップでは、収集する個人情報の変更、利用目的の変更、またはその他プライバシーポリシーの変更を行う際は、当ページへの変更をもって公表とさせていただきます。
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </Container>

            <Footer />

            <FooterNav />
        </Box>
    );
};

export default PrivacyPolicy;
