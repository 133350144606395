import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Heading from "../../../components/general/Heading";

const PaymentMethod = () => {
    return (
        <Box>
            <Heading title={"支払い方法について"} />

            <Flex flexDir={"column"} gap={5}>
                {/* credit */}
                <Box mt={5}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        クレジット
                    </Text>

                    <Flex flexDir={"column"} ml={2}>
                        <Flex gap={1}>
                            <Image src="https://img.shop-pro.jp/common/card2.gif" />
                            <Image src="https://img.shop-pro.jp/common/card6.gif" />
                            <Image src="https://img.shop-pro.jp/common/card18.gif" />
                            <Image src="https://img.shop-pro.jp/common/card0.gif" />
                            <Image src="https://img.shop-pro.jp/common/card9.gif" />
                        </Flex>
                        <Text>※離島への配送について</Text>
                        <Text>離島への配送追加料金は、カート内で加算されません。</Text>
                        <Text>
                            その場合は、「受注確認メール」でお支払総額をお知らせ致します。クレジットカードのお支払い額もこちらで訂正します。
                        </Text>
                        <Text>必ず、金額のご確認をお願い致します。</Text>
                    </Flex>
                </Box>

                {/* commodity cash on delivery */}

                <Box mt={5}>
                    <Text fontSize={"lg"} fontWeight={"semibold"}>
                        商品代引き
                    </Text>

                    <Flex flexDir={"column"} ml={2}>
                        <Text>代引き手数料（税込）</Text>
                        <Text>商品代金合計</Text>
                        <Flex ml={1} flexDir={"column"}>
                            <Text>・1万円未満 … 330円</Text>
                            <Text>・3万円未満 … 440円</Text>
                            <Text>・10万円未満 … 660円</Text>
                        </Flex>
                        <Text mt={3}>※離島への配送について</Text>
                        <Text>離島への配送追加料金は、カート内で加算されません。</Text>
                        <Text>その場合は、「受注確認メール」でお支払総額をお知らせ致します。</Text>
                        <Text>必ず、金額のご確認をお願い致します。</Text>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default PaymentMethod;
