import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Stack,
    Text,
    Textarea,
    useToast,
    VStack
} from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ReCAPTCHA from "react-google-recaptcha";

// form
import * as yup from "yup";
import { useFormik } from "formik";
import CustomInputField from "../../../components/input/CustomInputField";
import { globalStyles } from "../../../theme/styles";
import CustomTextArea from "../../../components/input/CustomTextArea";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import InquiryService from "../../../services/InquiryService";
import UserProfileService from "../../../services/UserProfileService";
import config from "../../../utils/config";

import ReactGA from "react-ga";

const Inquiry = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const User = useSelector((state: any) => state.Profile.user);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const [selectedFileError, setSelectedFileError] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);

    const [productImages, setProductImages] = useState<any>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const onSelectFile = (event: any) => {
        const selectedFiles = event.target.files;

        const selectedFilesArray: any = Array.from(selectedFiles);
        setProductImages(selectedFilesArray);
    };

    const onSubmit = (values: any, action: any) => {
        const formData = new FormData();

        for (let value in values) {
            formData.append(value, values[value]);
        }

        if (productImages.length > 0) {
            for (let i = 0; i < productImages.length; i++) {
                formData.append("images", productImages[i], productImages[i]?.name);
            }
        }

        dispatch(
            InquiryService.createInquiry(
                { token: Auth.token, data: formData },
                (responseData: any) => {
                    toast({
                        title: t("messages.inquiry.form_success"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });

                    navigate(-1);
                },
                (errorData: any) => {
                    return toast({
                        title: errorData.response?.data?.message
                            ? errorData.response?.data?.message
                            : errorData?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const inquirySchema = yup.object().shape({
        name: yup.string().required(t("form_errors.required_fields")),
        email: yup.string().email(t("form_errors.invalid_email")).required(t("form_errors.required_fields")),
        title: yup.string().required(t("form_errors.required_fields")),
        content: yup.string().required(t("form_errors.required_fields"))
    });

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: "",
            email: "",
            title: "",
            content: ""
        },
        validationSchema: inquirySchema,
        onSubmit
    });

    const [isDisabled, setIsDisabled] = useState(true);

    const onChange = () => {
        setIsDisabled(false);
    };

    const getUserDetails = () => {
        setIsLoading(true);
        dispatch(
            UserProfileService.getProfileDetails(
                { token: Auth.token },
                (responseData: any) => {
                    responseData.data?.last_name
                        ? setFieldValue("name", `${responseData?.data?.last_name} ${responseData.data?.first_name}`)
                        : responseData?.data?.name
                        ? setFieldValue("name", responseData?.data?.name)
                        : "";

                    responseData.data?.email ? setFieldValue("email", responseData.data?.email) : "";
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            getUserDetails();
        }
    }, [Auth.token]);

    const handleClose = () => {
        navigate("/account");
    };

    return (
        <Box>
            <Container maxW={"7xl"}>
                <Heading my={4}>
                    <Text>{t("account.inquiry")}</Text>
                </Heading>

                <Box>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <Stack spacing={4} position={"relative"}>
                            <CustomInputField
                                label={t("account.inquiry_mgmt.full_name")}
                                name="name"
                                type="text"
                                value={values.name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.name}
                                touched={touched.name}
                                isMandatory={true}
                            />
                            <CustomInputField
                                label={t("account.inquiry_mgmt.email")}
                                name="email"
                                type="text"
                                value={values.email}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.email}
                                touched={touched.email}
                                isMandatory={true}
                            />
                            <CustomInputField
                                label={t("account.inquiry_mgmt.inquiry_title")}
                                name="title"
                                type="text"
                                value={values.title}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.title}
                                touched={touched.title}
                                isMandatory={true}
                            />
                            <CustomTextArea
                                label={t("account.inquiry_mgmt.inquiry_content")}
                                name="content"
                                value={values.content}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.content}
                                touched={touched.content}
                                isMandatory={true}
                            />

                            <FormControl>
                                <FormLabel> {t("account.inquiry_mgmt.add_a_photo")}</FormLabel>
                                <Input
                                    type="file"
                                    name="images"
                                    height="100%"
                                    width="100%"
                                    aria-hidden="true"
                                    accept="image/*"
                                    onChange={(e) => {
                                        onSelectFile(e);
                                    }}
                                    multiple
                                />
                                {selectedFileError && (
                                    <Text fontSize={"sm"} mt={1} color={"red.300"}>
                                        {t("form_errors.required_fields")}
                                    </Text>
                                )}
                            </FormControl>
                        </Stack>

                        <Box mt={2}>
                            <ReCAPTCHA sitekey={config.CAPCHA_CLIENT_SECRET || ""} onChange={onChange} />
                        </Box>

                        <Flex alignItems={"center"} gap={3} mt={2}>
                            <Button
                                size="md"
                                bgColor={globalStyles.colors.lightGreen}
                                _active={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                _hover={{
                                    bg: globalStyles.colors.lightGreen
                                }}
                                color={"white"}
                                isDisabled={isDisabled}
                                type={"submit"}
                            >
                                {t("account.inquiry_mgmt.send")}
                            </Button>

                            <Button
                                size="md"
                                bgColor={globalStyles.colors.btn.lightGray}
                                color={"white"}
                                _active={{
                                    bg: globalStyles.colors.btn.lightGray
                                }}
                                _hover={{
                                    bg: globalStyles.colors.btn.lightGray
                                }}
                                onClick={handleClose}
                            >
                                {t("account.cancel")}
                            </Button>
                        </Flex>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default Inquiry;
