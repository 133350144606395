import React, { useEffect, useState } from "react";
import { Box, Container, Flex, Grid, GridItem, Heading, Input, Text } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";
import { globalStyles } from "../../theme/styles";
import ReactGA from "react-ga";

// icons
import { SearchIcon } from "../../utils/Icons";

// components
import FooterNav from "../../components/footer/FooterNav";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import ProductService from "../../services/ProductService";
import ProductCard from "../../components/product/ProductCard";
import LoadingScreen from "../../components/loading/LoadingScreen";
import UserProfileService from "../../services/UserProfileService";
import Footer from "../../components/footer/Footer";

const Search = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const LikedProduct = useSelector((state: any) => state.Profile.user?.product_likes);

    const SearchedProducts = useSelector((state: any) => state.Product.searchedProducts);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const [isLoading, setIsLoading] = useState(true);

    const { t } = useTranslation();

    const [product, setProduct] = useState("");

    const handleSearchedProduct = (e: any) => {
        if (e) {
            e.preventDefault();
        }
        setIsLoading(true);

        dispatch(
            UserProfileService.getProfileDetails(
                { token: Auth.token },
                (responseData: any) => {},
                (errorData: any) => {}
            )
        );

        dispatch(
            ProductService.getSearchedProducts(
                { token: Auth.token, search: product },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            const d = undefined;
            handleSearchedProduct(d);
        }
    }, [Auth.token]);

    return (
        <Box paddingBottom={12}>
            <Container maxW="7xl" minH={"md"}>
                <Heading my={3}>
                    <Text>{t("pages.search")}</Text>
                </Heading>

                <Flex flexDir={"column"} mt={4} gap={3}>
                    <Box>
                        {/* searchbox  */}
                        <form onSubmit={handleSearchedProduct}>
                            <Box position={"relative"} bgColor={"white"} rounded={"xl"}>
                                <Box pos={"relative"}>
                                    <Input
                                        placeholder={t("search.looking_for_something")}
                                        height={"14"}
                                        rounded={"xl"}
                                        name={"product"}
                                        value={product}
                                        onChange={(e) => {
                                            setProduct(e.target.value);
                                        }}
                                    />

                                    <Flex
                                        as="button"
                                        // onClick={}
                                        type="submit"
                                        position={"absolute"}
                                        right={"2"}
                                        top={"2"}
                                        p={3}
                                        rounded={"lg"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        bgColor={globalStyles.colors.darkGreen}
                                        cursor={"pointer"}
                                        zIndex={2}
                                    >
                                        <SearchIcon />
                                    </Flex>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Flex>

                {isLoading ? (
                    <LoadingScreen />
                ) : (
                    <Flex flexDir={"column"}>
                        <Text mt={5} fontSize={"lg"}>
                            {t("search.results")} ({SearchedProducts.length})
                        </Text>

                        <Grid templateColumns={"repeat(1, 1fr)"} gap={6}>
                            {SearchedProducts.map((product: any) => {
                                let isLike = false;
                                LikedProduct.map((productId: any) => {
                                    if (productId == product._id) {
                                        isLike = true;
                                    }
                                });
                                return (
                                    <GridItem key={product._id}>
                                        <ProductCard
                                            product={product}
                                            isLike={isLike}
                                            handleAllLikedProduct={() => {}}
                                        />
                                    </GridItem>
                                );
                            })}
                        </Grid>
                    </Flex>
                )}
            </Container>

            <Footer />

            <FooterNav />
        </Box>
    );
};

export default Search;
