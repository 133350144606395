import React, { lazy, Suspense, useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Text,
    Radio,
    RadioGroup,
    Stack,
    Divider,
    useDisclosure
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { setSelectedAddress, setSelectedUser } from "../../store/actions/user";

import CartService from "../../services/CartService";
import AddressService from "../../services/AddressService";
import OrderService from "../../services/OrderService";

// icons
import { AiFillEdit } from "react-icons/ai";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { globalStyles } from "../../theme/styles";
import ReactGA from "react-ga";

import LoadingScreen from "../../components/loading/LoadingScreen";
import config from "../../utils/config";
import UserProfileService from "../../services/UserProfileService";
import { numberWithCommas } from "../../utils/helperFunctions";

import UserProfile from "../../components/account/profile/UserProfile";
import AddressCard from "../../components/account/address/AddressCard";
import ProductsGrid from "./components/ProductsGrid";

const Checkout = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const Productdata = useSelector((state: any) => state.Cart.cart);
    const Cartdata = useSelector((state: any) => state.Cart);
    const User = useSelector((state: any) => state.Profile.user);

    // address
    const UserAddress = useSelector((state: any) => state.Address.allAddress);
    const SelectedAddress = useSelector((state: any) => state.Address.selectedAddress);
    const [address, setAddress] = React.useState("");

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [isCashonDelivery, setIsCashonDelivery] = useState("false");

    const [subTotalAmount, setSubTotalAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [cashOnDeliveryAmount, setCashOnDeliveryAmount] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const handleAddressDetails = () => {
        dispatch(
            AddressService.getAllAddress(
                { token: Auth.token },
                (responseData: any) => {
                    if (responseData.data.rows.length === 0) {
                        navigate("/account/address/create");
                    } else {
                        const address = responseData?.data?.rows[0];
                        dispatch(setSelectedAddress(address._id));
                        setAddress(address._id);
                    }

                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    const getCartDetails = () => {
        setIsLoading(true);
        dispatch(
            CartService.getCartDetails(
                { token: Auth.token },
                (responseData: any) => {
                    if (responseData?.status === 202) {
                        navigate("/cart");
                    }
                    const items = responseData.data.cartDetails.items;
                    const array: any = [];
                    items.map((item: any) => {
                        array.push(Number(item.product_id.price) * Number(item.items));
                    });

                    const sumWithInitial = array.reduce((a: any, b: any) => Number(a) + Number(b), 0);

                    setSubTotalAmount(sumWithInitial);

                    setTotalAmount(Number(responseData.data.totalAmount) - Number(responseData.data.codCharge));
                },
                (errorData: any) => {}
            )
        );

        dispatch(
            UserProfileService.getProfileDetails(
                { token: Auth.token },
                (responseData: any) => {
                    if (!responseData.data.first_name) {
                        navigate("/account/profile/create");
                    } else {
                        handleAddressDetails();
                    }
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            getCartDetails();
        }
    }, [Auth.token]);

    const handleProfileEdit = () => {
        navigate("/account/profile/edit");
        dispatch(setSelectedUser({ ...User, isEdit: true }));
    };

    const handleAddAddress = () => {
        navigate("/account/address/create");
    };

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleCODOrder = () => {
        setIsSubmitting(true);
        const data = {
            address_id: address,
            payment_type: config.PAYMENT_TYPE[0],
            cart_id: Cartdata?.cartId
        };
        dispatch(
            OrderService.createOrder(
                { token: Auth.token, data },
                (responseData: any) => {
                    dispatch(
                        CartService.getCartDetails(
                            { token: Auth.token },
                            (responseData: any) => {},
                            (errorData: any) => {}
                        )
                    );
                    const orderId = responseData.data._id;
                    setIsSubmitting(false);
                    navigate(`/thankyou/${orderId}`);

                    //you can manage state here and if any action is required
                },
                (errorData: any) => {
                    navigate("/account/history");
                    setIsSubmitting(false);
                }
            )
        );
    };

    const handleLineOrder = () => {
        setIsSubmitting(true);

        const data = {
            address_id: address,
            payment_type: config.PAYMENT_TYPE[1],
            cart_id: Cartdata?.cartId,
            // successURL: "https://" + window.location.hostname + "/thankyou",
            // cancelURL: "https://" + window.location.hostname + "/payment-failure",
            successURL: "https://liff.line.me/" + config.LIFF_ID + "/thankyou",
            cancelURL: "https://liff.line.me/" + config.LIFF_ID + "/payment-failure"
        };
        dispatch(
            OrderService.createOrder(
                { token: Auth.token, data },
                (responseData: any) => {
                    dispatch(
                        CartService.getCartDetails(
                            { token: Auth.token },
                            (responseData: any) => {},
                            (errorData: any) => {}
                        )
                    );

                    setIsSubmitting(false);

                    window.location.replace(responseData?.data?.paymentUrl?.web);

                    //you can manage state here and if any action is required
                },
                (errorData: any) => {
                    setIsSubmitting(false);
                    navigate("/account/history");
                }
            )
        );
    };

    useEffect(() => {
        if (isCashonDelivery == "true") {
            setCashOnDeliveryAmount(Cartdata?.codCharge);
            setTotalAmount(Cartdata?.totalAmount);
        } else {
            setCashOnDeliveryAmount(0);
            setTotalAmount(Number(Cartdata?.totalAmount) - Number(Cartdata?.codCharge));
        }
    }, [isCashonDelivery]);

    return (
        <Box>
            {isLoading || Cartdata.cart.length === 0 ? (
                <LoadingScreen />
            ) : (
                <Flex flexDir={"column"} gap={3} position={"relative"} pt={5}>
                    {/* checkout  */}
                    <Heading
                        borderBottom={"1px solid"}
                        borderColor={globalStyles.colors.border.lightGray}
                        pb={3}
                        maxW={"7xl"}
                        px={5}
                    >
                        <Text>{t("checkout.checkout")}</Text>
                    </Heading>

                    <Container maxW={"7xl"}>
                        <Flex flexDir={"column"} gap={4}>
                            {/* UserProfile */}
                            <Box borderColor={globalStyles.colors.border.lightGray} pb={3}>
                                <Flex justifyContent={"space-between"}>
                                    <Text as={"h2"} fontSize={"lg"} fontWeight={"semibold"}>
                                        {t("checkout.account_details")}
                                    </Text>

                                    <Flex
                                        justifyContent={"end"}
                                        alignItems={"center"}
                                        gap={2}
                                        cursor={"pointer"}
                                        onClick={handleProfileEdit}
                                    >
                                        <Icon as={AiFillEdit} />
                                        <Text>{t("checkout.edit")}</Text>
                                    </Flex>
                                </Flex>

                                <Box mt={2}>
                                    <UserProfile
                                        name={`${User?.last_name} ${User?.first_name}`}
                                        phone={User?.phone_number}
                                    />
                                </Box>
                            </Box>

                            {/* address_details */}
                            <Box pb={3}>
                                <Flex justifyContent={"space-between"}>
                                    <Text as={"h2"} fontSize={"lg"} fontWeight={"semibold"}>
                                        {t("checkout.address_details")}
                                    </Text>
                                    {UserAddress.length < 5 && (
                                        <Flex
                                            justifyContent={"end"}
                                            alignItems={"center"}
                                            gap={2}
                                            cursor={"pointer"}
                                            onClick={handleAddAddress}
                                        >
                                            <Icon as={AiFillEdit} />
                                            <Text>{t("checkout.add")}</Text>
                                        </Flex>
                                    )}
                                </Flex>

                                <Box
                                    mt={2}
                                    bg={"whiteAlpha.500"}
                                    width="full"
                                    shadow={"lg"}
                                    border={"1px"}
                                    borderColor={"lightgray"}
                                    borderRadius={"lg"}
                                >
                                    <RadioGroup value={address} onChange={setAddress}>
                                        <Stack direction="column">
                                            {UserAddress.map((address: any, index: number) => (
                                                <Radio key={address._id} w={"full"} value={address._id} px={4}>
                                                    {index > 0 && <Divider height="5px" />}

                                                    <AddressCard address={address} />
                                                </Radio>
                                            ))}
                                        </Stack>
                                    </RadioGroup>

                                    {/* <Flex flexDir={"column"} p={2} gap={3} w={"full"}>
                                        {UserAddress.map((address: any) => (
                                            <AddressCard address={address} key={address._id} />
                                        ))}
                                    </Flex> */}
                                </Box>
                            </Box>

                            {/* order_Details */}

                            <Box pb={3}>
                                <Text as={"h2"} fontSize={"lg"} fontWeight={"semibold"}>
                                    {t("checkout.order_Details")}
                                </Text>

                                <Flex flexDir={"column"}>
                                    {Productdata.map((product: any) => (
                                        <ProductsGrid key={product._id} product={product} />
                                    ))}
                                </Flex>

                                <Flex flexDir={"column"} w={"full"}>
                                    <Flex
                                        justifyContent={"space-between"}
                                        borderBottom={"1px"}
                                        borderColor={globalStyles.colors.border.gray}
                                        py={2}
                                    >
                                        <Text>{t("checkout.sub_total")}</Text>
                                        <Text>{numberWithCommas(subTotalAmount)}円</Text>
                                    </Flex>

                                    <Flex
                                        justifyContent={"space-between"}
                                        borderBottom={"1px"}
                                        borderColor={globalStyles.colors.border.gray}
                                        py={2}
                                    >
                                        <Text>{t("checkout.delivery_charges")}</Text>
                                        <Text>
                                            {Cartdata?.deliveryCharge &&
                                                numberWithCommas(Number(Cartdata?.deliveryCharge))}
                                            円
                                        </Text>
                                    </Flex>

                                    <Flex
                                        justifyContent={"space-between"}
                                        borderBottom={"1px"}
                                        borderColor={globalStyles.colors.border.gray}
                                        py={2}
                                    >
                                        <Text>{t("checkout.cod_charges")}</Text>
                                        <Text>{numberWithCommas(Number(cashOnDeliveryAmount))}円</Text>
                                    </Flex>

                                    <Flex
                                        justifyContent={"space-between"}
                                        borderBottom={"1px"}
                                        borderColor={globalStyles.colors.border.gray}
                                        py={2}
                                    >
                                        <Text fontSize={"xl"} fontWeight={"bold"}>
                                            {t("checkout.total")} ({t("product.tax_included")})
                                        </Text>
                                        <Text fontSize={"xl"} fontWeight={"bold"}>
                                            {numberWithCommas(Number(totalAmount))}円
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex w={"full"} flexDir={"column"} gap={3} alignItems={"center"} my={5}>
                                    <RadioGroup value={isCashonDelivery} onChange={setIsCashonDelivery}>
                                        <Stack direction="column">
                                            <Box
                                                px={3}
                                                rounded={"lg"}
                                                border={"1px"}
                                                borderColor={"#a19696"}
                                                bgColor={globalStyles.colors.background.offwhite}
                                            >
                                                <Radio value="false" w={"full"}>
                                                    <Flex p={2}>
                                                        <Text fontSize={"lg"} fontWeight={"semibold"}>
                                                            {t("checkout.pay_with_line_pay")}
                                                        </Text>
                                                    </Flex>
                                                </Radio>
                                            </Box>

                                            <Box
                                                px={3}
                                                rounded={"lg"}
                                                border={"1px"}
                                                borderColor={"#a19696"}
                                                bgColor={globalStyles.colors.background.offwhite}
                                            >
                                                <Radio value="true" w={"full"}>
                                                    <Flex flexDir={"column"} p={2}>
                                                        <Text fontSize={"lg"} fontWeight={"semibold"}>
                                                            {t("checkout.cash_on_delivery")}
                                                        </Text>
                                                        <Text>{t("checkout.cash_on_Delivery_charge")}</Text>
                                                    </Flex>
                                                </Radio>
                                            </Box>
                                        </Stack>
                                    </RadioGroup>

                                    <Box my={3} w={"full"}>
                                        <Button
                                            w={"full"}
                                            bgColor={globalStyles.colors.lightGreen}
                                            _active={{
                                                bg: globalStyles.colors.lightGreen
                                            }}
                                            _hover={{
                                                bg: globalStyles.colors.lightGreen
                                            }}
                                            color={"white"}
                                            isLoading={isSubmitting}
                                            onClick={() => {
                                                if (isCashonDelivery == "true") {
                                                    return handleCODOrder();
                                                }

                                                handleLineOrder();
                                            }}
                                        >
                                            {t("checkout.pay")}
                                            <Box as="span" ml={1}>
                                                {/* {numberWithCommas(totalAmount)}円 */}
                                            </Box>
                                        </Button>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                    </Container>
                </Flex>
            )}
        </Box>
    );
};

export default Checkout;
