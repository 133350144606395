import React, { useState } from "react";
import { Box, Flex, Icon, Radio, Text, RadioGroup } from "@chakra-ui/react";

import { useTranslation } from "react-i18next";

import { globalStyles } from "../../../theme/styles";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAddress } from "../../../store/actions/user";
import { AppDispatch } from "../../../store";
import { adjustPincode } from "../../../utils/helperFunctions";

interface UserAddressesProps {
    address: any;
}

const AddressCard: React.FC<UserAddressesProps> = ({ address }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const SelectedAddress = useSelector((state: any) => state.Address.selectedAddress);

    const { t } = useTranslation();

    return (
        <Box
            // onClick={() => {
            //     dispatch(setSelectedAddress(address));
            // }}
            cursor={"pointer"}
            borderColor={"lightgray"}

            // bgColor={
            //     SelectedAddress?._id === address?._id
            //         ? globalStyles.colors.lightGreen
            //         : globalStyles.colors.background.gray
            // }
            // color={SelectedAddress?._id === address?._id ? "white" : "black"}
        >
            <Box p="2">
                <Flex mt="1" justifyContent="space-between" alignContent="center">
                    <Box fontSize="2xl" fontWeight="semibold" as="h4" lineHeight="tight">
                        <Text fontSize="lg" fontWeight="semibold">
                            {address?.user_name}
                        </Text>
                        {/* 
                        <Text fontSize="sm" fontWeight="medium">
                            {address?.type}
                        </Text> */}
                        <Text fontSize="sm" fontWeight="medium">
                            {address?.zipcode && adjustPincode(address?.zipcode)}, {address?.state},{address?.city},
                            {address?.street_address}
                        </Text>
                    </Box>
                </Flex>
            </Box>
        </Box>
    );
};

export default AddressCard;
