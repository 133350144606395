import { GET_ALL_ORDERS_SUCCESS, GET_ORDER_SUCCESS, TOGGLE_VIEW_CANCEL_ORDER } from "../../actionTypes";

const INITIAL_STATE = {
    allOrders: [],
    selectedOrder: {},
    viewCancel: true
};

const OrderHistory = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                allOrders: action.payload.data.rows
            };
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                selectedOrder: action.payload.data
            };
        case TOGGLE_VIEW_CANCEL_ORDER:
            return {
                ...state,
                viewCancel: action.payload
            };

        default:
            return state;
    }
};

export default OrderHistory;
