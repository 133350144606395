import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import ViewProductsButton from "../general/ViewProductsButton";

interface EmptyProductsProps {
    title: String;
}

const EmptyProducts = ({ title }: EmptyProductsProps) => {
    return (
        <Flex minH={"52vh"} alignItems={"center"} justifyContent={"center"} gap={3} flexDir={"column"}>
            <Text>{title}</Text>
            <ViewProductsButton />
        </Flex>
    );
};

export default EmptyProducts;
