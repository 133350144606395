import { API_INVOCATION, CONFIRM_PAYMENT, RETRY_PAYMENT } from "../store/actionTypes";
import { DELETE, GET, POST } from "../utils/apiConstant";
import { CONFIRMPAYMENT, RETRYPAYMENT } from "../utils/url";

class PaymentService {
    // confirm Payment
    confirmPayment(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: CONFIRM_PAYMENT,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: CONFIRMPAYMENT,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }

    retrivePayment(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: RETRY_PAYMENT,
            method: POST,
            apiConfig: {
                headers: {
                    Authorization: `Bearer ${_payload.token}`
                }
            },
            url: RETRYPAYMENT,
            resolve,
            reject,
            data: _payload.data
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new PaymentService();
