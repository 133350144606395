import React, { useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Radio,
    RadioGroup,
    Stack,
    Text,
    useToast
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomInputField from "../../../components/input/CustomInputField";
import { globalStyles } from "../../../theme/styles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import UserService from "../../../services/UserProfileService";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import ReactGA from "react-ga";
import CustomInputNumber from "../../../components/input/CustomInputNumber";
import CustomDateField from "../../../components/input/CustomDateField";

const UserProfileForm = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const User = useSelector((state: any) => state.Profile.selectedUser);
    const Auth = useSelector((state: any) => state.Auth);

    const { t } = useTranslation();
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    const onSubmit = async (values: any, actions: any) => {
        dispatch(
            UserService.setProfileDetails(
                { data: values, token: Auth?.token },
                (responseData: any) => {
                    setTimeout(() => {
                        toast({
                            title: t("messages.user.user_update_success"),
                            status: "success",
                            isClosable: true
                        });
                        navigate(-1);
                        actions.resetForm();
                    }, 1300);
                },
                (errorData: any) => {
                    toast({
                        title: errorData.response?.data?.message
                            ? errorData.response?.data?.message
                            : errorData?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                    return;
                }
            )
        );
    };

    const userProfileSchema = yup.object().shape({
        first_name: yup.string().required(t("form_errors.required_fields")),
        last_name: yup.string().required(t("form_errors.required_fields")),
        email: yup.string().email(t("form_errors.invalid_email")),
        phone_number: yup
            .string()
            .min(10, t("form_errors.invalid_phone_number"))
            .required(t("form_errors.required_fields"))
    });

    const { values, errors, touched, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            last_name: "",   
            first_name: "",
            last_name_kana: "",
            first_name_kana: "",
            email: "",
            gender: "",
            phone_number: "",
            date_of_birth: ""
        },
        validationSchema: userProfileSchema,
        onSubmit
    });

    const handleClose = () => {
        navigate("/account");
    };

    useEffect(() => {
        if (User?.isEdit) {
            setFieldValue("last_name", User?.last_name);
            setFieldValue("first_name", User?.first_name);
            setFieldValue("last_name_kana", User?.last_name_kana);
            setFieldValue("first_name_kana", User?.first_name_kana);
            setFieldValue("email", User?.email);
            setFieldValue("gender ", User?.gender);
            const gender = document.getElementById(User?.gender);
            if (gender) {
                gender?.click();
            }
            setFieldValue("phone_number", User?.phone_number);
            setFieldValue("date_of_birth", dayjs(User?.date_of_birth).format("YYYY-MM-DD"));
        }
    }, []);

    const genderOptions = ["MALE", "FEMALE", "OTHER"];

    return (
        <Box>
            <Container maxW={"7xl"}>
                <Heading my={4}>
                    <Text>{t("account.profile_mgmt.add_information")}</Text>
                </Heading>
                <Box>
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <Stack spacing={4} position={"relative"}>
                            <Flex justifyContent={"space-between"} gap={3}>
                                <Box flex={1}>
                                    <CustomInputField
                                        label={t("account.profile_mgmt.last_name")}
                                        name="last_name"
                                        type="text"
                                        value={values.last_name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errors={errors.last_name}
                                        touched={touched.last_name}
                                        isMandatory={true}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <CustomInputField
                                        label={t("account.profile_mgmt.first_name")}
                                        name="first_name"
                                        type="text"
                                        value={values.first_name}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errors={errors.first_name}
                                        touched={touched.first_name}
                                        isMandatory={true}
                                    />
                                </Box>
                            </Flex>
                            <Flex justifyContent={"space-between"} gap={3}>
                                <Box flex={1}>
                                    <CustomInputField
                                        label={t("account.profile_mgmt.surname_kana")}
                                        name="last_name_kana"
                                        type="text"
                                        value={values.last_name_kana}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errors={errors.last_name_kana}
                                        touched={touched.last_name_kana}
                                        isMandatory={false}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <CustomInputField
                                        label={t("account.profile_mgmt.name_kana")}
                                        name="first_name_kana"
                                        type="text"
                                        value={values.first_name_kana}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        errors={errors.first_name_kana}
                                        touched={touched.first_name_kana}
                                        isMandatory={false}
                                    />
                                </Box>
                            </Flex>
                            {/* email */}
                            <CustomInputField
                                label={t("account.profile_mgmt.email")}
                                name="email"
                                type="email"
                                value={values.email}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.email}
                                touched={touched.email}
                                isMandatory={false}
                            />
                            {/* phone_number */}
                            <CustomInputNumber
                                label={t("account.profile_mgmt.phone_number")}
                                name="phone_number"
                                value={values.phone_number}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.phone_number}
                                touched={touched.phone_number}
                                maxlength={11}
                                isMandatory={true}
                            />

                            {/* date_of_birth */}
                            <CustomDateField
                                label={t("account.profile_mgmt.date_of_birth")}
                                name="date_of_birth"
                                value={values.date_of_birth}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errors={errors.date_of_birth}
                                touched={touched.date_of_birth}
                                isMandatory={false}
                            />

                            {/* gender  */}
                            <FormControl id="gender">
                                <FormLabel> {t("account.profile_mgmt.gender")}</FormLabel>
                                <RadioGroup
                                    name="gender"
                                    id="gender"
                                    value={values.gender}
                                    onClick={handleChange}
                                    onBlur={handleBlur}
                                    // onClick={(e: any) => {
                                    //     changeFormData(e);
                                    // }}
                                >
                                    <Flex gap={4}>
                                        {genderOptions.map((gender: any) => (
                                            <Radio value={gender} id={gender} key={gender}>
                                                {t(`account.profile_mgmt.${gender}`)}
                                            </Radio>
                                        ))}
                                    </Flex>
                                </RadioGroup>
                            </FormControl>
                            <Flex alignItems={"center"} gap={3} paddingBottom={2}>
                                <Button
                                    size="md"
                                    bgColor={globalStyles.colors.lightGreen}
                                    _active={{
                                        bg: globalStyles.colors.lightGreen
                                    }}
                                    _hover={{
                                        bg: globalStyles.colors.lightGreen
                                    }}
                                    color={"white"}
                                    disabled={isSubmitting}
                                    type={"submit"}
                                >
                                    {t("account.save")}
                                </Button>

                                <Button
                                    size="md"
                                    bgColor={globalStyles.colors.btn.lightGray}
                                    color={"white"}
                                    _active={{
                                        bg: globalStyles.colors.btn.lightGray
                                    }}
                                    _hover={{
                                        bg: globalStyles.colors.btn.lightGray
                                    }}
                                    onClick={handleClose}
                                >
                                    {t("account.cancel")}
                                </Button>
                            </Flex>
                        </Stack>
                    </form>
                </Box>
            </Container>
        </Box>
    );
};

export default UserProfileForm;
