import { ADD_CART_INFORMATION, GET_CART_SUCCESS } from "../../actionTypes";

const INITIAL_STATE = {
    cart: [],
    deliveryCharge: "",
    codCharge: "",
    totalAmount: "",
    totalTaxAmount: "",
    totalWeight: "",
    cartId: ""
};

const Cart = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case GET_CART_SUCCESS:
            if (action.payload.status === 202) {
                return {
                    ...state,
                    cart: [],
                    deliveryCharge: "",
                    codCharge: "",
                    totalAmount: "",
                    totalTaxAmount: "",
                    totalWeight: "",
                    cartId: ""
                };
            } else {
                const cart = action.payload.data;

                return {
                    ...state,
                    cart: cart.cartDetails.items,
                    deliveryCharge: cart.deliveryCharge,
                    codCharge: cart.codCharge,
                    totalAmount: cart.totalAmount,
                    totalTaxAmount: cart.totalTaxAmount,
                    totalWeight: cart.totalWeight,
                    cartId: cart.cartDetails._id
                };
            }

        // case ADD_CART_INFORMATION:

        //     return {
        //         ...state,
        //         deliveryCharge: ""
        //     };
        default:
            return state;
    }
};

export default Cart;
