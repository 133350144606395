import React, { useState } from "react";
import { Badge, Box, Container, Divider, Flex, Heading, Image, SimpleGrid, Text } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { numberWithCommas } from "../../../utils/helperFunctions";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../theme/styles";

interface OrderCardProps {
    order: any;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    return (
        <Box
            px={3}
            py={2}
            shadow={"lg"}
            border={"1px"}
            borderColor={"lightgray"}
            borderRadius={"lg"}
            boxShadow={"base"}
            onClick={() => {
                navigate(`/account/history/${order?._id}`);
            }}
        >
            <Box display={"flex"} alignItems={"center"} mb={2} justifyContent={"space-between"}>
                <Text textAlign={"right"} fontSize={14} color={"gray"}>
                    {order?.createdAt && dayjs(order?.createdAt).format("YYYY/MM/DD HH:mm")}
                </Text>
                <Text>
                    {t("account.history_mgmt.total")}
                    <span style={{ fontWeight: "600" }}> {order?.total_items} </span>
                    {t("account.history_mgmt.items")}
                </Text>
            </Box>

            <Flex mt={2} gap={4}>
                <Flex flexDir={"column"}>
                    <Text>{t("account.history_mgmt.id")}:</Text>
                    <Text>{t("account.history_mgmt.payment_type")}:</Text>
                    <Text>{t("account.history_mgmt.payment_status")}: </Text>
                    <Text>
                        {t("account.history_mgmt.total")}({t("product.tax_included")}):
                    </Text>
                </Flex>
                <Flex flexDir={"column"}>
                    <Text fontWeight={"450"}>{order?._id}</Text>
                    <Text fontWeight={"450"}>{t(`account.history_mgmt.${order?.payment_type}`)}</Text>
                    <Badge
                        variant="solid"
                        bgColor={
                            order?.status === "DELIVERED"
                                ? "green"
                                : order?.status === "CANCEL"
                                ? "red"
                                : order?.status === "PAYMENT-PENDING"
                                ? "orange"
                                : globalStyles.colors.btn.blue
                        }
                        w={"fit-content"}
                    >
                        {t(`account.history_mgmt.${order?.status}`)}
                    </Badge>
                    {/* <Text fontWeight={"450"}>{t(`account.history_mgmt.${order?.status}`)}</Text> */}
                    <Text fontWeight={"450"}>
                        {order?.total_amount && numberWithCommas(Number(order?.total_amount))}円
                    </Text>
                </Flex>
            </Flex>
        </Box>
    );
};

export default OrderCard;
