// Empty Page
import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";

// useTranslation
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

const NotFound = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Flex height={"100vh"} justifyContent={"center"} alignItems={"center"}>
            {t("messages.404error")}
        </Flex>
    );
};

export default NotFound;
