import { SET_SELECTED_ADDRESS, SET_SELECTED_ORDER, SET_SELECTED_USER, SET_USER_PROFILE } from "../../actionTypes";

// setUserProfile
export const getUserProfile = (_payload: any) => {
    return { type: SET_USER_PROFILE, payload: _payload };
};

export const setSelectedUser = (_payload: any) => {
    return { type: SET_SELECTED_USER, payload: _payload };
};

// Address
export const setSelectedAddress = (_payload: any) => {
    return { type: SET_SELECTED_ADDRESS, payload: _payload };
};

// Order
export const setSelectedOrder = (_payload: any) => {
    return { type: SET_SELECTED_ORDER, payload: _payload };
};
