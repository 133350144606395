import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const ProductDescription = () => {
    const ProductData = useSelector((state: any) => state.Product.product);

    function parseHTML() {
        const desc = document.getElementById("desc");
        if (desc && ProductData?.details) {
            desc.innerHTML = JSON.parse(ProductData?.details);
        }
    }

    useEffect(() => {
        parseHTML();
    }, [ProductData]);

    return <div id="desc"></div>;
};

export default ProductDescription;
