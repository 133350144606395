import { Box, Image } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
    return (
        <Box>
            <Image src="/assets/images/banner.jpeg" w={"full"} h={"10rem"} objectFit="cover" />
        </Box>
    );
};

export default Banner;
