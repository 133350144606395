import React from "react";
import { Flex, Stack, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const PermissionBox = () => {
    const { t } = useTranslation();
    return (
        <Flex align={"center"} justify={"center"} p={5} bg={"#f7f8f8"}>
            <Stack boxShadow={"2xl"} bg={"white"} rounded={"xl"} p={8} spacing={8} align={"center"}>
                <Stack align={"center"} spacing={2}>
                    <Heading textTransform={"uppercase"} fontSize={"3xl"} color={"gray.800"}>
                        {t("home.auth.sorry")}!
                    </Heading>
                    <Text fontSize={"lg"} color={"gray.500"} textAlign={"center"}>
                        {t("home.auth.please_grant_profile_permission")}
                    </Text>
                </Stack>
            </Stack>
        </Flex>
    );
};

export default PermissionBox;
