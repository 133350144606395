import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box, Container, Flex, Heading, Text, Divider } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";

import UserProfileService from "../../services/UserProfileService";

import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

// icons
import { BsTranslate } from "react-icons/bs";
import { ACCOUNT_ITEMS } from "../../utils/constants";

// components
import FooterNav from "../../components/footer/FooterNav";

const AccountProfile = lazy(() => import("../../components/account/AccountProfile"));
const Signout = lazy(() => import("../../components/account/Signout"));
const AccountItem = lazy(() => import("../../components/account/AccountItem"));

import LoadingScreen from "../../components/loading/LoadingScreen";

const Account = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);

    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleProfileDetails = () => {
        setIsLoading(true);
        dispatch(
            UserProfileService.getProfileDetails(
                { token: Auth.token },
                (responseData: any) => {},
                (errorData: any) => {}
            )
        );

        setIsLoading(false);
    };

    useEffect(() => {
        if (Auth.token !== "") {
            handleProfileDetails();
        }
    }, [Auth.token]);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <Suspense fallback={<LoadingScreen />}>
                    <Flex flexDir={"column"} gap={3} paddingBottom={12} position={"relative"}>
                        <Container maxW={"7xl"}>
                            <Flex my={3} justifyContent={"space-between"} alignItems={"center"}>
                                <Heading>
                                    <Text>{t("pages.account")}</Text>
                                </Heading>
                            </Flex>

                            {/* profile  */}
                            <AccountProfile />

                            <Box
                                borderRadius={"lg"}
                                backgroundColor={"common.white"}
                                className="box-shadow2"
                                marginTop={8}
                            >
                                {/* Member */}
                                <AccountItem account={ACCOUNT_ITEMS[0]} />

                                <Divider color={"#EDF1F7"} width={"88%"} marginLeft={"auto"} />

                                {/* Address */}
                                <AccountItem account={ACCOUNT_ITEMS[1]} />

                                <Divider color={"#EDF1F7"} width={"90%"} marginLeft={"auto"} />

                                {/* Order History  */}

                                <AccountItem account={ACCOUNT_ITEMS[2]} />

                                <Divider color={"#EDF1F7"} width={"88%"} marginLeft={"auto"} />

                                {/* Favourite  */}

                                <AccountItem account={ACCOUNT_ITEMS[3]} />

                                <Divider color={"#EDF1F7"} width={"88%"} marginLeft={"auto"} />

                                {/* Inquiry */}
                                <AccountItem account={ACCOUNT_ITEMS[4]} />

                                <Divider color={"#EDF1F7"} width={"88%"} marginLeft={"auto"} />

                                {/* Faq */}
                                <AccountItem account={ACCOUNT_ITEMS[5]} />

                                <Divider color={"#EDF1F7"} width={"88%"} marginLeft={"auto"} />

                                {/* Privacy */}
                                <AccountItem account={ACCOUNT_ITEMS[6]} />

                                <Divider color={"#EDF1F7"} width={"88%"} marginLeft={"auto"} />

                                {/* Conditions */}
                                <AccountItem account={ACCOUNT_ITEMS[7]} />
                            </Box>

                            {/* signout  */}

                            {/* <Box w={"full"}>
                                <Signout />
                            </Box> */}
                        </Container>

                        <FooterNav />
                    </Flex>
                </Suspense>
            )}
        </Box>
    );
};

export default Account;
