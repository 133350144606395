import React from "react";

import { Box, Image, UnorderedList } from "@chakra-ui/react";

const ImageCarousel = () => {
    const styleSheet = {
        article: {
            display: "flex",
            width: "400%",
            animation: "bannermove 20s linear infinite"
        }
    };

    const Images = [
        "/assets/images/slide1.jpeg",
        "/assets/images/slide2.jpg",
        "/assets/images/slide3.jpg",
        "/assets/images/slide4.jpg",
        "/assets/images/slide5.jpg",
        "/assets/images/slide6.jpg",
        "/assets/images/slide7.jpg",
        "/assets/images/slide8.jpg"
    ];

    return (
        <article style={styleSheet.article}>
            <Box as="div" w={"full"}>
                <UnorderedList display={"flex"} listStyleType={"none"} pl={0} m={0} gap={0}>
                    {Images.map((image: any, i: number) => {
                        return (
                            <li key={image}>
                                <Box>
                                    <Image src={image} h={"24"} />
                                </Box>
                            </li>
                        );
                    })}
                </UnorderedList>
            </Box>
        </article>
    );
};

export default ImageCarousel;
