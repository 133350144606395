import React, { lazy, Suspense, useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Text, useToast, Select } from "@chakra-ui/react";

// redux
import ProductService from "../../services/ProductService";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import CartService from "../../services/CartService";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { numberWithCommas } from "../../utils/helperFunctions";

// icons
import { FaShoppingCart } from "react-icons/fa";

import { globalStyles } from "../../theme/styles";
import ReactGA from "react-ga";

// components
import Footer from "../../components/footer/Footer";
import FooterNav from "../../components/footer/FooterNav";

const ProductsGrid = lazy(() => import("./components/ProductsGrid"));
const ProductRating = lazy(() => import("./components/ProductRating"));
const ProductComments = lazy(() => import("./components/ProductComments"));
const ProductCarousel = lazy(() => import("./components/ProductCarousel"));

import LoadingScreen from "../../components/loading/LoadingScreen";
import RatingStar from "../../components/star/RatingStar";
import Favourite from "./components/Favourite";
import { emptySelectedProduct, setSelectedProduct } from "../../store/actions/product";
import ProductDescription from "./components/ProductDescription";
import WriteReview from "./components/WriteReview";

const Product = () => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const ProductData = useSelector((state: any) => state.Product.product);
    const LikedProducts = useSelector((state: any) => state.Product.allLikedProducts);

    const ProductReviews = useSelector((state: any) => state.Product.productReviews);

    const { t } = useTranslation();
    const { productId } = useParams();
    const toast = useToast();

    const [isLoading, setIsLoading] = useState(false);
    const [isPurchased, setIsPurchased] = useState(false);

    const [quantity, setQuantity] = useState(1);
    const quantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.pageview(window.location.pathname + window.location.search);

        return () => {
            dispatch(emptySelectedProduct({}));
        };
    }, []);

    //  favourite

    const [isLike, setIsLike] = useState(false);

    const handleProductReview = () => {
        dispatch(
            ProductService.getProductReview(
                { token: Auth.token, id: productId },
                (responseData: any) => {
                    setIsLoading(false);
                },
                (errorData: any) => {}
            )
        );
    };

    const handleProductById = () => {
        setIsLoading(true);

        dispatch(
            ProductService.getProduct(
                { token: Auth.token, id: productId },
                (responseData: any) => {
                    dispatch(setSelectedProduct(responseData.data));
                    setIsPurchased(responseData.data.isUserPurchased);
                },
                (errorData: any) => {}
            )
        );
    };

    const handleProduct = () => {
        dispatch(
            ProductService.getFavouriteProduct(
                { token: Auth.token },
                (responseData: any) => {
                    if (responseData.status == 200) {
                        const favouriteProduct = responseData.data.rows;
                        for (let i = 0; i < favouriteProduct.length; i++) {
                            if (favouriteProduct[i]._id == productId) {
                                setIsLike(true);
                            }
                        }
                    } else {
                        setIsLike(false);
                    }
                },
                (errorData: any) => {}
            )
        );
    };

    useEffect(() => {
        if (Auth.token !== "") {
            handleProductById();

            handleProduct();
            handleProductReview();
        }
    }, [Auth.token, productId]);

    const handleCart = () => {
        const data = {
            product_id: ProductData._id,
            items: quantity
        };
        dispatch(
            CartService.addCartDetails(
                { token: Auth.token, data },
                (responseData: any) => {
                    toast({
                        title: t("messages.cart.item_is_added_in_cart_successfully"),
                        status: "success",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });

                    dispatch(
                        CartService.getCartDetails(
                            { token: Auth.token },
                            (responseData: any) => {},
                            (errorData: any) => {}
                        )
                    );
                },
                (errorData: any) => {
                    return toast({
                        title: errorData.response?.data?.message
                            ? errorData.response?.data?.message
                            : errorData?.message,
                        status: "error",
                        variant: "solid",
                        duration: 2000,
                        position: "top-right",
                        isClosable: true
                    });
                }
            )
        );
    };

    const handleShareProduct = () => {
        if (!window.liff.isApiAvailable("shareTargetPicker")) {
            return console.log("Not available");
        }
        window.liff
            .shareTargetPicker(
                [
                    {
                        type: "text",
                        text: "Hello, World!"
                    }
                ],
                {
                    isMultiple: true
                }
            )
            .then(function (res: any) {
                if (res) {
                    console.log(`[${res.status}] Message sent!`);
                } else {
                    // sending message canceled
                    console.log("TargetPicker was closed!");
                }
            })
            .catch(function (error: any) {
                console.log("something wrong happen");
            });
    };

    return (
        <Box>
            {isLoading || Object.keys(ProductData).length === 0 ? (
                <LoadingScreen />
            ) : (
                <Suspense fallback={<LoadingScreen />}>
                    <Box pos={"relative"} pb={"14"}>
                        <Flex flexDir={"column"} py={5} mx={3}>
                            <ProductCarousel cover={ProductData.cover_photo} images={ProductData.product_photos} />

                            <Flex
                                flexDir={"column"}
                                gap={3}
                                py={5}
                                justifyContent={"center"}
                                alignItems={"center"}
                                id={"product"}
                            >
                                {/* name  */}
                                <Heading
                                    lineHeight={1.1}
                                    fontWeight={600}
                                    fontSize={{ base: "2xl", sm: "4xl", lg: "5xl" }}
                                    textAlign={"center"}
                                >
                                    {ProductData?.name}
                                </Heading>
                                {/* price */}
                                <Text color={"gray.900"} fontWeight={300} fontSize={"2xl"} textAlign={"center"}>
                                    {numberWithCommas(ProductData?.price)}円({t("product.tax_included")})
                                </Text>
                                {/* <Text>{ProductData?.weight} kg</Text> */}

                                <a href="#product-rating">
                                    <Flex alignItems={"center"} gap={3}>
                                        <RatingStar
                                            rating={ProductReviews?.avgRating ? Number(ProductReviews?.avgRating) : 0}
                                        />
                                        <Text textDecoration={"underline"}>
                                            (
                                            {ProductReviews?.totalRating
                                                ? numberWithCommas(Number(ProductReviews?.totalRating))
                                                : 0}
                                            )
                                        </Text>
                                    </Flex>
                                </a>

                                {/* favourite */}
                                <Favourite isLike={isLike} handleProduct={handleProduct} />
                            </Flex>

                            {/* qty and add cart */}

                            <Flex
                                flexDir={"column"}
                                borderY={"1px"}
                                py={5}
                                borderColor={globalStyles.colors.border.lightGray}
                            >
                                <Flex gap={3} mb={3} alignItems={"center"}>
                                    <Text>{t("product.qty")}</Text>
                                    <Select
                                        w={"24"}
                                        h={"10"}
                                        bgColor={globalStyles.colors.btn.gray}
                                        onChange={(e) => {
                                            setQuantity(Number(e.target.value));
                                        }}
                                        value={quantity}
                                        name="quantity"
                                    >
                                        {quantities.map((value: any, i: number) => {
                                            return (
                                                <option value={value} key={value}>
                                                    {value}個
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </Flex>

                                <Box w={"full"}>
                                    <Button
                                        rounded={"md"}
                                        size={"lg"}
                                        w={"full"}
                                        bgColor={globalStyles.colors.lightGreen}
                                        _active={{
                                            bg: globalStyles.colors.lightGreen
                                        }}
                                        _hover={{
                                            bg: globalStyles.colors.lightGreen
                                        }}
                                        color={"white"}
                                        textTransform={"uppercase"}
                                        onClick={handleCart}
                                        flex={1}
                                        leftIcon={<FaShoppingCart />}
                                    >
                                        {t("product.add_to_cart")}
                                    </Button>
                                </Box>
                            </Flex>

                            <Box py={5}>
                                {/* productData.name  */}
                                <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: "3xl" }}>
                                    {ProductData?.name}
                                </Heading>
                                {/* social share  */}
                                {/* <Flex gap={4} my={5}>
                                    <Button
                                        bgColor={"#06c755"}
                                        leftIcon={<Icon as={FaTwitter} />}
                                        color={"white"}
                                        rounded={"none"}
                                        size={"xs"}
                                        onClick={handleShareProduct}
                                    >
                                        {t("product.line")}
                                    </Button>
                                </Flex> */}
                            </Box>

                            {/* product details  */}
                            <ProductDescription />

                            {ProductReviews && <ProductRating rating={ProductReviews} />}
                            {isPurchased && (
                                <WriteReview
                                    handleProductReview={handleProductReview}
                                    setIsPurchased={setIsPurchased}
                                />
                            )}
                            {ProductReviews && <ProductComments reviews={ProductReviews.reviews} />}

                            <Box w={"full"} my={10}>
                                <a href="#product">
                                    <Button
                                        rounded={"md"}
                                        size={"lg"}
                                        w={"full"}
                                        bgColor={globalStyles.colors.lightGreen}
                                        _active={{
                                            bg: globalStyles.colors.lightGreen
                                        }}
                                        _hover={{
                                            bg: globalStyles.colors.lightGreen
                                        }}
                                        color={"white"}
                                        textTransform={"uppercase"}
                                        onClick={handleCart}
                                        flex={1}
                                        leftIcon={<FaShoppingCart />}
                                    >
                                        {t("product.add_to_cart")}
                                    </Button>
                                </a>
                            </Box>
                        </Flex>

                        {/* favourite  */}

                        {LikedProducts.length > 0 && (
                            <ProductsGrid
                                bgColor={globalStyles.colors.background.offwhite}
                                heading={t("product.favourite")}
                                products={LikedProducts}
                            />
                        )}

                        <Footer />

                        <FooterNav />
                    </Box>
                </Suspense>
            )}
        </Box>
    );
};

export default Product;
