import {
    Box,
    Container,
    Divider,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    Link,
    SimpleGrid,
    Stack,
    Text,
    VStack
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { globalStyles } from "../../theme/styles";

const Footer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const footerLinks = [
        {
            id: 1,
            path: "/faq",
            label: "faq"
        },
        {
            id: 2,
            path: "/privacy-policy",
            label: "pricay_policy"
        }
        // {
        //     id: 3,
        //     path: "/terms-and-conditions",
        //     label: "terms_and_conditions"
        // }
    ];
    return (
        <Box bg={globalStyles.colors.footer.brown}>
            <Stack
                direction={{
                    base: "column",
                    lg: "row"
                }}
                w="full"
                justify="space-between"
            >
                {/* <Flex justify="center">
                    <Image
                        src="/assets/logo.png"
                        alt="Company Logo"
                        rounded="lg"
                        width={{
                            base: "250px"
                        }}
                        height={{
                            base: "75px",
                            lg: "100px"
                        }}
                        my={{
                            base: 2,
                            lg: 0
                        }}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                </Flex> */}
                <HStack
                    alignItems="start"
                    flex={1}
                    justify="space-around"
                    textAlign={{
                        base: "center",
                        md: "left"
                    }}
                    my={{
                        base: 2,
                        lg: 0
                    }}
                >
                    <Grid gap={4} w={"full"} templateColumns="repeat(2, 1fr)">
                        {footerLinks.map((footer: any) => {
                            return (
                                <GridItem key={footer.id} w="100%">
                                    <Text
                                        fontSize={"12px"}
                                        color={"white"}
                                        onClick={() => {
                                            navigate(footer.path);
                                        }}
                                        cursor={"pointer"}
                                    >
                                        {t(`pages.${footer.label}`)}
                                    </Text>
                                </GridItem>
                            );
                        })}
                    </Grid>
                </HStack>
                <Flex my={4} justifyContent={"center"}>
                    <Text
                        fontSize={"12px"}
                        color={"white"}
                        onClick={() => {
                            navigate("/terms-and-conditions");
                        }}
                        cursor={"pointer"}
                    >
                        {t(`pages.terms_and_conditions`)}
                    </Text>
                </Flex>
            </Stack>
            <VStack pt={3}>
                <Text textAlign="center" fontSize="smaller" color={"white"}>
                    R１　もの補助　※ものづくり補助金事業以外での使用禁止
                </Text>
            </VStack>
            <VStack py={3}>
                <Text textAlign="center" fontSize="smaller" color={"white"}>
                    {t("footer.all_rights_reserved")}
                </Text>
            </VStack>
        </Box>
    );
};

export default Footer;
