import { AUTHENTICATE_API_SUCCESS } from "../../actionTypes";

const INITIAL_STATE = {
    userId: "",
    displayName: "",
    pictureUrl: "",
    token: ""
};

const Auth = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
    switch (action.type) {
        case AUTHENTICATE_API_SUCCESS:
            const user = action.payload.data.result;
            return {
                userId: user.userId,
                displayName: user.displayName,
                pictureUrl: user.pictureUrl,
                token: action.payload.data.token
            };

        default:
            return state;
    }
};

export default Auth;
