import { Flex, Spinner } from "@chakra-ui/react";
import { globalStyles } from "../../theme/styles";
import { LoaderIcon } from "../../utils/Icons";

const LoadingScreen = () => {
    return (
        <Flex minH={"100vh"} justifyContent={"center"} alignItems={"center"}>
            <LoaderIcon width={"30px"} height={"30px"} />
        </Flex>
    );
};

export default LoadingScreen;
