import { API_INVOCATION, AUTHENTICATE_API } from "../store/actionTypes";
import { POST } from "../utils/apiConstant";
import { USERAUTH } from "../utils/url";

class AuthService {
    login(_payload: any, resolve: any, reject: any) {
        const payload = {
            action: AUTHENTICATE_API,
            method: POST,
            apiConfig: {
                headers: {
                    "Content-Type": "application/json"
                }
            },
            url: USERAUTH,
            resolve,
            reject,
            data: _payload
        };
        return { type: API_INVOCATION, payload };
    }
}

export default new AuthService();
