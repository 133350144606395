import { Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaHeart } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ProductService from "../../../services/ProductService";
import { AppDispatch } from "../../../store";
import { globalStyles } from "../../../theme/styles";

interface FavouriteProps {
    isLike: boolean;
    handleProduct: any;
}

const Favourite: React.FC<FavouriteProps> = ({ isLike, handleProduct }) => {
    // redux
    const dispatch = useDispatch<AppDispatch>();
    const Auth = useSelector((state: any) => state.Auth);
    const ProductData = useSelector((state: any) => state.Product.product);

    const { t } = useTranslation();

    const [productLiked, setProductLiked] = useState(false);

    const handleLike = () => {
        if (productLiked) {
            // remove like
            dispatch(
                ProductService.removeLikedProduct(
                    { token: Auth.token, product_id: ProductData._id },
                    (responseData: any) => {
                        setProductLiked(false);
                        handleProduct();
                    },
                    (errorData: any) => {}
                )
            );
        } else {
            // like
            dispatch(
                ProductService.addLikedProduct(
                    { token: Auth.token, product_id: ProductData._id },
                    (responseData: any) => {
                        setProductLiked(true);
                        handleProduct();
                    },
                    (errorData: any) => {}
                )
            );
        }
    };

    useEffect(() => {
        setProductLiked(isLike);
    }, [isLike]);

    return (
        <Flex flex={1} justifyContent={"center"} onClick={handleLike} alignItems={"center"} gap={2} cursor={"pointer"}>
            <FaHeart
                color={`${
                    productLiked ? globalStyles.colors.background.pink : globalStyles.colors.background.lightGray
                }`}
                size={"1.5em"}
            />
            <Text color={productLiked ? globalStyles.colors.background.pink : "black"}>{t("product.favourite")}</Text>
        </Flex>
    );
};

export default Favourite;
